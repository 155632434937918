import React, { Component } from 'react';
import { Layout, Form, Input, Button, Alert, Row, Col } from 'antd';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import axios from 'axios';

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { loginError: '' };
    console.log(this.props.auth);
  }

  onSubmit = (values) => {
    console.log(values);

    axios
      .post(
        '/auth/login',
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        // Enter login authentication flow

        if (response.status === 200) {
          // TODO: Redirect to default view for each user type
          window.location.replace('/');
        }
      })
      .catch((error) => {
        console.log('login error: ', error);
        this.setState({
          loginError: 'There was an error logging in. Please enter the correct email and password.',
        });
      });
  };

  onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  renderContent = () => {
    switch (this.props.auth) {
      case null:
        // Wait while user is being determined
        return;
      case '':
        // If no user, show login screen
        return (
          <Layout style={{ minHeight: '100vh', padding: '10%' }} className="layout login-layout">
            <div style={{ textAlign: 'center', paddingBottom: '4%' }}>
              <h1>Ops Portal Login</h1>
            </div>
            <Row>
              <Col xs={24} sm={18} md={16} lg={8} xl={8} style={{ margin: '0 auto' }}>
                <Form
                  style={{ flex: 1 }}
                  name="login"
                  initialValues={{ remember: true }}
                  onFinish={this.onSubmit}
                  onFinishFailed={this.onSubmitFailed}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                      {
                        type: 'email',
                        message: 'The input is not a valid email.',
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                      Log In
                    </Button>
                    {this.state.loginError ? (
                      <>
                        <br />
                        <br />
                        <Alert message={this.state.loginError} type="error" />
                      </>
                    ) : null}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Layout>
        );
      default:
        // If user exists, redirect to home (Clients Screen)
        return <Navigate to="/" />;
    }
  };

  render() {
    return <>{this.renderContent()}</>;
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, actions)(LoginScreen);
