import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Alert, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

export default function AddAircraftModal(props) {
  const [form] = Form.useForm();
  const [finError, setFinError] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  console.log(props.aircraftSelected);

  const handleSelectChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleSubmitAdd = () => {
    let values = form.getFieldsValue(true);
    axios
      .post('/api/aircraft', { fin: values.fin, mediaType: values.mediaType, model: values.model })
      .then((res) => {
        if (res?.data?.code === 11000) {
          setFinError('This fin already exists');
        } else {
          props.onOk();
          form.resetFields();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSubmitEdit = () => {
    let values = form.getFieldsValue(true);
    axios
      .patch(`/api/aircraft/${props.aircraftSelected._id}`, {
        fin: values.fin,
        mediaType: values.mediaType,
      })
      .then((res) => {
        props.onOk();
        messageApi.success('Data edited successfully!');
        props.fetchAircraft();
        form.resetFields();
      })
      .catch((err) => {
        messageApi.error('Please, check your connection and try again!');
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (props?.aircraftSelected) {
      form.setFieldValue('fin', props.aircraftSelected.fin);
      form.setFieldValue('model', props.aircraftSelected.model);
      form.setFieldValue('mediaType', props.aircraftSelected.mediaType);
    }
  }, [form, props?.aircraftSelected]);

  return (
    <>
      <Modal
        title={props?.aircraftSelected ? 'Edit Aircraft' : 'Add Aircraft'}
        visible={props.visible}
        onOk={props?.aircraftSelected ? handleSubmitEdit : handleSubmitAdd}
        onCancel={props.onCancel}
      >
        <Form form={form} layout="vertical" name="addAircraft" autoComplete="off">
          <Form.Item label="Fin" name="fin" rules={[{ required: true, message: 'Please add a Fin' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Aircraft Model" name="model" rules={[{ required: true, message: 'Please add a model' }]}>
            <Input disabled={props?.aircraftSelected} />
          </Form.Item>
          <Form.Item
            label="Media Type"
            name="mediaType"
            rules={[{ required: true, message: 'Please add a media type' }]}
          >
            <Select style={{ width: 120 }} onChange={handleSelectChange}>
              <Option value="i4500">i4500</Option>
              <Option value="GoGo">GoGo</Option>
              <Option value="Avant">Avant</Option>
              <Option value="ATPDR">ATPDR</Option>
            </Select>
          </Form.Item>
        </Form>
        {finError ? (
          <>
            <Alert message={finError} type="error" />
          </>
        ) : null}
      </Modal>

      {contextHolder}
    </>
  );
}
