import React from 'react';
import { Card } from 'antd';
import { formatDate } from '../../utils/formatDate';
import dayjs from 'dayjs';

export default function I4500DisplayCard({ report }) {
  console.log(report);

  return (
    <Card title={`i4500 Loading Details`}>
      <div>
        <b>User</b>
        <p>{report?.i4500Report?.user || '-'}</p>
      </div>
      <div>
        <b>Station</b>
        <p>{report?.i4500Report?.station || '-'}</p>
      </div>
      <div>
        <b>Load Started Time</b>
        <p>{report?.i4500Report?.loadStartedTime || '-'}</p>
      </div>
      <div>
        <b>Date</b>
        <p>{report?.i4500Report?.date ? report?.i4500Report?.date.replace('T', ' ').split('.')[0] + ' GMT' : '-'}</p>
      </div>
      <div>
        <b>Time On Aircraft</b>
        <p>{report?.i4500Report?.timeOnAircraft || '-'}</p>
      </div>
      <div>
        <b>Time Off Aircraft</b>
        <p>{report?.i4500Report?.timeOffAircraft || '-'}</p>
      </div>

      <div>
        <b>Load Time (mins)</b>
        <p>{report?.i4500Report?.loadTimeMins || '-'}</p>
      </div>
      <div>
        <b>Total Time (mins)</b>
        <p>{report?.i4500Report?.totalTimeMins || '-'}</p>
      </div>
      <div>
        <b>Current Active Month on AC</b>
        <p>{report?.i4500Report?.currentAircraftMonthOnAC || '-'}</p>
      </div>
      <div>
        <b>% at Start</b>
        <p>{report?.i4500Report?.percentAtStart || '-'}</p>
      </div>
      <div>
        <b>Safety Ads</b>
        <p>{report?.i4500Report?.safetyAds ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>Safety Video</b>
        <p>{report?.i4500Report?.safetyVideo ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>XM Radio</b>
        <p>{report?.i4500Report?.xmRadio ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>Hollywood and New TV Titles</b>
        <p>{report?.i4500Report?.hollywoodNewTv ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>Prams</b>
        <p>{report?.i4500Report?.prams ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>Other</b>
        <p>{report?.i4500Report?.other ? <>Checked</> : <>Unchecked</>}</p>
      </div>
      <div>
        <b>PDL 1</b>
        <p>{report?.i4500Report?.pdl1 || '-'}</p>
      </div>
      <div>
        <b>PDL 2</b>
        <p>{report?.i4500Report?.pdl2 || '-'}</p>
      </div>
      <div>
        <b>HD 1</b>
        <p>{report?.i4500Report?.hd1 || '-'}</p>
      </div>
      <div>
        <b>HD 2</b>
        <p>{report?.i4500Report?.hd2 || '-'}</p>
      </div>
      <div>
        <b>DSU 1</b>
        <p>{report?.i4500Report?.dsu1 || '-'}</p>
      </div>
      <div>
        <b>DSU 2</b>
        <p>{report?.i4500Report?.dsu2 || '-'}</p>
      </div>
      <div>
        <b>DSU 3</b>
        <p>{report?.i4500Report?.dsu3 || '-'}</p>
      </div>
      <div>
        <b>DSU 4</b>
        <p>{report?.i4500Report?.dsu4 || '-'}</p>
      </div>
      <div>
        <b>MDATA OFFLOAD (CURRENT)</b>
        <p>{report?.i4500Report?.mdataOffloadCurrent || '-'}</p>
      </div>
      <div>
        <b>MDATA OFFLOAD (ARCHIVE)</b>
        <p>{report?.i4500Report?.mdataOffloadArchive || '-'}</p>
      </div>
      <div>
        <b>VIK Kit</b>
        <p>{report?.i4500Report?.vikKit || '-'}</p>
      </div>
      <div>
        <b>VIK Kit Replaced?</b>
        <p>{report?.i4500Report?.vikKitReplaced || '-'}</p>
      </div>
      <div>
        <b>Power Source Type</b>
        <p>{report?.i4500Report?.powerSourceType || '-'}</p>
      </div>
      <div>
        <b>Confirmed CIL as Displayed on Aircraft</b>
        <p>{report?.i4500Report?.confirmedCIL || '-'}</p>
      </div>
      <div>
        <b>Active Month</b>
        <p>{report?.i4500Report?.activeMonth || '-'}</p>
      </div>
      <div>
        <b>Billing Month</b>
        <p>{report?.i4500Report?.billingMonth || '-'}</p>
      </div>
      <div>
        <b>Load %</b>
        <p>{report?.i4500Report?.loadPercent || '-'}</p>
      </div>
      <div>
        <b>Loading Delay Code</b>
        <p>{report?.i4500Report?.loadingDelayCode || '-'}</p>
      </div>
      <div>
        <b>Loading Delay Notes</b>
        <p>{report?.i4500Report?.loadingDelayNotes || '-'}</p>
      </div>
      <div>
        <b>Notes</b>
        <p>{report?.i4500Report?.notes || '-'}</p>
      </div>
    </Card>
  );
}
