import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Row, Col, Spin, Button, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from './layouts/MainLayout';

export default function ProfileScreen() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [changePassForm] = Form.useForm();
  const [changingPassword, setChangingPassword] = useState(false);
  const [changePassError, setChangePassError] = useState('');

  // Run this function on password change
  const onFinish = (values) => {
    setChangePassError(''); // Remove any error messages that might be there

    axios
      .post('/auth/login', {
        email: auth.email,
        password: values.currentPassword,
      })
      .then((res) => {
        setChangePassError('');
        if (values.newPassword === values.confirmNewPassword) {
          // if the newPassword and confirmNewPassword fields match, go ahead and update the user's password
          axios
            .patch(`/auth/${auth._id}/changePassword`, {
              password: values.newPassword,
            })
            .then(() => {
              changePassForm.resetFields();
              setChangingPassword(false);
              axios.get('/auth/logout').then((res) => (window.location.href = '/login'));
            });
        } else {
          // If they do not match, show an error message
          setChangePassError('The new passwords do not match. Please try again.');
        }
      })
      .catch((err) => {
        // If the current password is wrong, do not proceed to check for anything else. Show an error message.
        setChangePassError('You entered the incorrect current password. Please try again.');
      });
  };

  const onFinishFailed = (errorInfo) => {
    setChangingPassword(false);
    console.log('Failed:', errorInfo);
  };

  return (
    <MainLayout>
      {auth ? (
        <Row style={{ paddingTop: '3%' }}>
          <Col span={24}>
            <div style={{ marginRight: '4%' }}>
              <b>Reset Password</b>
            </div>
          </Col>

          <Col span={24}>
            {/* When the user clicks the change password button, show the change password form */}
            {changingPassword ? (
              <Form
                name="change-password"
                form={changePassForm}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="currentPassword"
                  style={{ width: '50%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your current password.',
                    },
                  ]}
                >
                  <Input.Password placeholder="Current password" />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  style={{ width: '50%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your new password.',
                    },
                  ]}
                >
                  <Input.Password placeholder="New password" />
                </Form.Item>

                <Form.Item
                  name="confirmNewPassword"
                  style={{ width: '50%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please re-type your new password.',
                    },
                  ]}
                >
                  <Input.Password placeholder="Re-type new password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: '50%' }}>
                    Submit
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: '50%' }}
                    onClick={() => {
                      setChangingPassword(false);
                      changePassForm.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>

                {changePassError ? <Alert style={{ width: '50%' }} message={changePassError} type="error" /> : null}
              </Form>
            ) : (
              <Button
                size="large"
                onClick={() => {
                  setChangingPassword(true);
                }}
              >
                Change Password
              </Button>
            )}
          </Col>
        </Row>
      ) : (
        <>
          <Spin />
        </>
      )}
    </MainLayout>
  );
}
