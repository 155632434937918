import React from 'react';
import { Card } from 'antd';
import { formatDate } from '../../utils/formatDate';

// TODO

export default function AvantDisplayCard({ report }) {
  return (
    <Card title={`Avant Loading Details`}>
      <div>
        <b>User</b>
        <p>{report?.avantReport?.user || '-'}</p>
      </div>
      <div>
        <b>Station</b>
        <p>{report?.avantReport?.station || '-'}</p>
      </div>
      <div>
        <b>TCL Used</b>
        <p>{report?.avantReport?.tclUsed || '-'}</p>
      </div>
      <div>
        <b>Date</b>
        <p>{report?.avantReport?.date ? report?.avantReport?.date.replace('T', ' ').split('.')[0] + ' GMT' : '-'}</p>
      </div>
      <div>
        <b>Load Time (Mins)</b>
        <p>{report?.avantReport?.loadTimeMins || '-'}</p>
      </div>
      <div>
        <b>Total Time (Mins)</b>
        <p>{report?.avantReport?.totalTimeMins || '-'}</p>
      </div>
      <div>
        <b>CIL</b>
        <p>{report?.avantReport?.cil || '-'}</p>
      </div>
      <div>
        <b>DTD S/N On</b>
        <p>{report?.avantReport?.dtdOn || '-'}</p>
      </div>
      <div>
        <b>DTD S/N Off</b>
        <p>{report?.avantReport?.dtdOff || '-'}</p>
      </div>
      <div>
        <b>DSU 1</b>
        <p>{report?.avantReport?.dsu1 || '-'}</p>
      </div>
      <div>
        <b>DSU 2</b>
        <p>{report?.avantReport?.dsu2 || '-'}</p>
      </div>
      <div>
        <b>Arrive (24 hours)</b>
        <p>{report?.avantReport?.arrive24h || '-'}</p>
      </div>
      <div>
        <b>Power TCL</b>
        <p>{report?.avantReport?.powerTCL || '-'}</p>
      </div>
      <div>
        <b>System Ready</b>
        <p>{report?.avantReport?.systemReady || '-'}</p>
      </div>
      <div>
        <b>Download Start</b>
        <p>{report?.avantReport?.downloadStart || '-'}</p>
      </div>
      <div>
        <b>Active DSU Downloaded</b>
        <p>{report?.avantReport?.activeDsuDownloaded || '-'}</p>
      </div>
      <div>
        <b>Active DSU Installed</b>
        <p>{report?.avantReport?.activeDsuInstalled || '-'}</p>
      </div>
      <div>
        <b>Active SVDU5 Downloaded</b>
        <p>{report?.avantReport?.activeSvdu5Downloaded || '-'}</p>
      </div>
      <div>
        <b>Active SVDU5 Installed</b>
        <p>{report?.avantReport?.activeSvdu5Installed || '-'}</p>
      </div>
      <div>
        <b>Inactive DSU Downloaded</b>
        <p>{report?.avantReport?.inactiveDsuDownloaded || '-'}</p>
      </div>
      <div>
        <b>Inactive DSU Installed</b>
        <p>{report?.avantReport?.inactiveDsuInstalled || '-'}</p>
      </div>
      <div>
        <b>Inactive SVDU5 Downloaded</b>
        <p>{report?.avantReport?.inactiveSvdu5Downloaded || '-'}</p>
      </div>
      <div>
        <b>Inactive SVDU5 Installed</b>
        <p>{report?.avantReport?.inactiveSvdu5Installed || '-'}</p>
      </div>
      <div>
        <b>DSU Inactive: Download @ 100%</b>
        <p>{report?.avantReport?.dsuInactiveDownloadAt100 || '-'}</p>
      </div>
      <div>
        <b>DSU Inactive: Installed @ 100%</b>
        <p>{report?.avantReport?.dsuInactiveInstalledAt100 || '-'}</p>
      </div>
      <div>
        <b>SVDU5 Inactive: Download @ 100%</b>
        <p>{report?.avantReport?.svdu5InactiveDownloadAt100 || '-'}</p>
      </div>
      <div>
        <b>SVDU5 Inactive: Installed @ 100%</b>
        <p>{report?.avantReport?.svdu5InactiveInstalledAt100 || '-'}</p>
      </div>
      <div>
        <b>Ok to remove TCL Loader (time 24 hrs)</b>
        <p>{report?.avantReport?.okToRemoveTclLoader || '-'}</p>
      </div>
      <div>
        <b>Active Month</b>
        <p>{report?.avantReport?.activeMonth || '-'}</p>
      </div>
      <div>
        <b>Quality Check (time 24 hrs)</b>
        <p>{report?.avantReport?.qualityCheck || '-'}</p>
      </div>
      <div>
        <b>Depart (time 24 hrs)</b>
        <p>{report?.avantReport?.depart || '-'}</p>
      </div>
      <div>
        <b>Load Percent</b>
        <p>{report?.avantReport?.loadPercent || '-'}</p>
      </div>
      <div>
        <b>Notes</b>
        <p>{report?.avantReport?.notes || '-'}</p>
      </div>
      <div>
        <b>Seats Not Working</b>
        <p>{report?.avantReport?.seatsNotWorking || '-'}</p>
      </div>
    </Card>
  );
}
