import React from 'react';
import { Table } from 'antd';

export default function MediaShippingTable({ data }) {
  const columns = [
    {
      title: 'Station',
      dataIndex: 'station',
      key: 'station',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Item(s)',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Content Month',
      dataIndex: 'contentMonth',
      key: 'contentMonth',
    },
    {
      title: 'Waybill Incoming',
      dataIndex: 'waybillIncoming',
      key: 'waybillIncoming',
    },
    {
      title: 'Waybill Outgoing',
      dataIndex: 'waybillOutgoing',
      key: 'waybillOutgoing',
    },
    {
      title: 'Ship To',
      dataIndex: 'shipTo',
      key: 'shipTo',
    },
    {
      title: 'Recieved From',
      dataIndex: 'recievedFrom',
      key: 'recievedFrom',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];

  return data?.length > 0 ? (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="_id"
      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
    />
  ) : null;
}
