import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import ContentLoadingDetailsModal from './ContentLoadingDetailsModal';

export default function ContentLoadingCard({ batch, title }) {
  const { aircraftReports } = batch;
  const [approvedReports, setApprovedReports] = useState([]);
  // const [totalReports, setTotalReports] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalI4500, setTotalI4500] = useState(0);
  const [totalGogo, setTotalGogo] = useState(0);
  const [totalAvant, setTotalAvant] = useState(0);
  const [totalAtpdr, setTotalAtpdr] = useState(0);

  const [i4500Loaded, setI4500Loaded] = useState(0);
  const [gogoLoaded, setGogoLoaded] = useState(0);
  const [avantLoaded, setAvantLoaded] = useState(0);
  const [avantDtdLoaded, setAvantDtdLoaded] = useState(0);
  const [atpdrLoaded, setAtpdrLoaded] = useState(0);

  // console.log('aircraftReports', aircraftReports);

  // Filter all approved reports
  useEffect(() => {
    if (aircraftReports) {
      // Reinitialize state
      setTotalI4500(0);
      setTotalGogo(0);
      setTotalAvant(0);
      setTotalAtpdr(0);
      setI4500Loaded(0);
      setGogoLoaded(0);
      setAvantLoaded(0);
      setAvantDtdLoaded(0);
      setAtpdrLoaded(0);

      let approved = aircraftReports.filter(
        (report) =>
          report?.i4500Report?.approved ||
          report?.gogoReport?.approved ||
          report?.avantReport?.approved ||
          report?.avantDtdReport?.approved ||
          report?.atpdrReport?.approved
      );

      setApprovedReports(approved);

      aircraftReports.forEach((report) => {
        // Count how many total reports should exist
        switch (report?.mediaType) {
          case 'i4500':
            setTotalI4500((prev) => prev + 1);
            break;
          case 'GoGo':
            setTotalGogo((prev) => prev + 1);
            break;
          case 'Avant':
            setTotalAvant((prev) => prev + 1);
            break;
          case 'ATPDR':
            setTotalAtpdr((prev) => prev + 1);
            break;
          default:
            break;
        }
      });
    }
  }, [aircraftReports]);

  // Sort aircraft reports by report type
  useEffect(() => {
    if (approvedReports) {
      // Sort by report type
      approvedReports.forEach((report) => {
        // Add up total percent loaded for each media type, so we can average it later

        if (report?.i4500Report?.loadPercent >= 100 && report?.i4500Report?.approved) {
          setI4500Loaded((prev) => prev + 1);
        } else if (report?.gogoReport?.loadPercent >= 100 && report?.gogoReport?.approved) {
          setGogoLoaded((prev) => prev + 1);
        } else if (report?.avantReport?.loadPercent >= 100 && report?.avantReport?.approved) {
          setAvantLoaded((prev) => prev + 1);
        } else if (report?.avantDtdReport?.loadPercent >= 100 && report?.avantDtdReport?.approved) {
          setAvantDtdLoaded((prev) => prev + 1);
        } else if (report?.atpdrReport?.loadPercent >= 100 && report?.atpdrReport?.approved) {
          setAtpdrLoaded((prev) => prev + 1);
        }
      });
    }
  }, [approvedReports]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <ContentLoadingDetailsModal
        aircraftReports={approvedReports}
        visible={isModalVisible}
        handleSubmit={handleOk}
        handleCancel={handleCancel}
      />
      <Card
        title={title}
        extra={
          <a href="#/" onClick={showModal}>
            View Full Report
          </a>
        }
      >
        <b>i4500</b>
        <p className="subtext">{`${i4500Loaded}/${totalI4500} Aircraft Loaded`}</p>
        <b>GoGo</b>
        <p className="subtext">{`${gogoLoaded}/${totalGogo} Aircraft Loaded`}</p>
        <b>Avant / Avant DTD</b>
        <p className="subtext">{`${avantLoaded + avantDtdLoaded}/${totalAvant} Aircraft Loaded`}</p>
        <b>ATPDR</b>
        <p className="subtext">{`${atpdrLoaded}/${totalAtpdr} Aircraft Loaded`}</p>
      </Card>
    </>
  );
}
