import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Button, Modal, Tag } from 'antd';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import DisplayMediaCard from '../components/DisplayMediaCard';
import { hasPermission } from '../helpers';
import { formatDate } from '../utils/formatDate';

const { Title } = Typography;

export default function SingleReportScreen() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [report, setReport] = useState({});
  const [editing, setEditing] = useState(false);
  const [waitingForApproval, setWaitingForApproval] = useState(false);
  const [waitingForSubmission, setWaitingForSubmission] = useState(false);
  const [viewReportsPermission, setViewReportsPermission] = useState(false);
  const [approveReportsPermission, setApproveReportsPermission] = useState(false);
  const [editReportsPermission, setEditReportsPermission] = useState(false);
  const [enterDataPermission, setEnterDataPermission] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  let { batchId, reportId } = useParams();

  console.log(waitingForSubmission, 'waitingForSubmission');

  console.log(report, 'report');

  useEffect(() => {
    if (roles && auth) {
      setViewReportsPermission(hasPermission('viewReports', auth.role, roles));
      setApproveReportsPermission(hasPermission('approveReports', auth.role, roles));
      setEditReportsPermission(hasPermission('editReports', auth.role, roles));
      setEnterDataPermission(hasPermission('enterData', auth.role, roles));
    }
  }, [roles, auth]);

  useEffect(() => {
    if (reportId && batchId) {
      axios.get(`/api/batch/${batchId}/report/${reportId}`, { withCredentials: true }).then((res) => {
        setReport(res.data);
        setIsSuccess(true);
      });
    }
  }, [reportId, batchId]);

  // If all fields are empty, set editing to true
  useEffect(() => {
    if (
      !report?.i4500Report?.user &&
      !report?.gogoReport?.user &&
      !report?.avantReport?.user &&
      !report?.avantDtdReport?.user &&
      !report?.atpdrReport?.user &&
      enterDataPermission &&
      editReportsPermission
    ) {
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [report]);

  useEffect(() => {
    let toExport = [];

    if (
      report?.i4500Report?.approved ||
      report?.gogoReport?.approved ||
      report?.avantReport?.approved ||
      report?.avantDtdReport?.approved ||
      report?.atpdrReport?.approved
    ) {
      setIsApproved(true);
    }

    if (
      report?.i4500Report?.waitingForApproval ||
      report?.gogoReport?.waitingForApproval ||
      report?.avantReport?.waitingForApproval ||
      report?.avantDtdReport?.waitingForApproval ||
      report?.atpdrReport?.waitingForApproval
    ) {
      setWaitingForApproval(true);
    }

    if (
      report?.i4500Report?.waitingForSubmission ||
      report?.gogoReport?.waitingForSubmission ||
      report?.avantReport?.waitingForSubmission ||
      report?.avantDtdReport?.waitingForSubmission ||
      report?.atpdrReport?.waitingForSubmission
    ) {
      setWaitingForSubmission(true);
    }

    switch (report.mediaType) {
      case 'i4500':
        setDataToExport([
          {
            fin: report?.fin,
            mediaType: report?.mediaType,
            model: report?.model,
            currentStatus: report?.currentStatus,
            i4500station: report?.i4500Report?.station,
            i4500date: report?.i4500Report?.date && formatDate(report?.i4500Report?.date),
            /* i4500loadStartingAt:
              report?.i4500Report?.loadStartingAt && formatDate(report?.i4500Report?.loadStartingAt),
            i4500loadStartedTime:
              report?.i4500Report?.loadStartedTime &&
              dayjs(report?.i4500Report?.loadStartedTime).format('YYYY-MM-DD HH:mm:ss'),
            i4500timeOnAircraft: report?.i4500Report?.timeOnAircraft, */
            i4500loadTimeMins: report?.i4500Report?.loadTimeMins,
            i4500totalTimeMins: report?.i4500Report?.totalTimeMins,
            i4500percentAtStart: report?.i4500Report?.percentAtStart,
            /*i4500safetyAds: report?.i4500Report?.safetyAds,
            i4500safetyVideo: report?.i4500Report?.safetyVideo,
            i4500xmRadio: report?.i4500Report?.xmRadio,
            i4500hollywoodNewTv: report?.i4500Report?.hollywoodNewTv,
            i4500prams: report?.i4500Report?.prams,
            i4500other: report?.i4500Report?.other,
            i4500pdl1: report?.i4500Report?.pdl1,
            i4500pdl2: report?.i4500Report?.pdl2,
            i4500hd1: report?.i4500Report?.hd1,
            i4500hd2: report?.i4500Report?.hd2, */
            i4500dsu1: report?.i4500Report?.dsu1,
            i4500dsu2: report?.i4500Report?.dsu2,
            i4500dsu3: report?.i4500Report?.dsu3,
            i4500dsu4: report?.i4500Report?.dsu4,
            /* i4500dsu5: report?.i4500Report?.dsu5,
            i4500dsu6: report?.i4500Report?.dsu6,
            i4500mdataOffloadCurrent: report?.i4500Report?.mdataOffloadCurrent,
            i4500mdataOffloadArchive: report?.i4500Report?.mdataOffloadArchive,
            i4500vikKit: report?.i4500Report?.vikKit,
            i4500vikKitReplaced: report?.i4500Report?.vikKitReplaced,
            i4500powerSourceType: report?.i4500Report?.powerSourceType, */
            i4500confirmedCIL: report?.i4500Report?.confirmedCIL,
            i4500activeMonth: report?.i4500Report?.activeMonth,
            /* i4500billingMonth: report?.i4500Report?.billingMonth,*/
            i4500loadPercent: report?.i4500Report?.loadPercent,
            /* i4500loadingDelayCode: report?.i4500Report?.loadingDelayCode,
            i4500loadingDelayNotes: report?.i4500Report?.loadingDelayNotes, */
            i4500notes: report?.i4500Report?.notes,
            /* i4500waitingForApproval: report?.i4500Report?.waitingForApproval,
            i4500approved: report?.i4500Report?.approved, */
          },
        ]);
        break;
      case 'GoGo':
        setDataToExport([
          {
            fin: report?.fin,
            mediaType: report?.mediaType,
            model: report?.model,
            currentStatus: report?.currentStatus,
            gogoUser: report?.gogoReport?.user,
            gogoStation: report?.gogoReport?.station,
            gogoDate: report?.gogoReport?.date,
            gogoSerialNumberOn: report?.gogoReport?.serialNumberOn,
            gogoSerialNumberOff: report?.gogoReport?.serialNumberOff,
            gogoWaitingForSubmission: report?.gogoReport?.waitingForSubmission,
            gogoWaitingForApproval: report?.gogoReport?.waitingForApproval,
            gogoApproved: report?.gogoReport?.approved,
            gogoLoadPercent: report?.gogoReport?.loadPercent,
            gogoNotes: report?.gogoReport?.notes,
          },
        ]);
        break;
      case 'Avant':
        setDataToExport([
          {
            fin: report?.fin,
            mediaType: report?.mediaType,
            model: report?.model,
            currentStatus: report?.currentStatus,
            // avantUser: report?.avantReport?.user,
            avantStation: report?.avantReport?.station,
            avantTCLUsed: report?.avantReport?.tclUsed,
            avantDate: report?.avantReport?.date && formatDate(report?.avantReport?.date),
            avantLoadTimeMins: report?.avantReport?.loadTimeMins,
            avantTotalTimeMins: report?.avantReport?.totalTimeMins,
            avantCil: report?.avantReport?.cil,
            avantDtdOn: report?.avantReport?.dtdOn,
            avantDtdOff: report?.avantReport?.dtdOff,
            avantDsu1: report?.avantReport?.dsu1,
            avantDsu2: report?.avantReport?.dsu2,
            avantArrive24h: report?.avantReport?.arrive24h,
            avantPowerTCL: report?.avantReport?.powerTCL,
            avantSystemReady: report?.avantReport?.systemReady,
            avantDownloadStart: report?.avantReport?.downloadStart,
            avantActiveDsuDownloaded: report?.avantReport?.activeDsuDownloaded,
            avantActiveDsuInstalled: report?.avantReport?.activeDsuInstalled,
            avantActiveSvdu5Downloaded: report?.avantReport?.activeSvdu5Downloaded,
            avantActiveSvdu5Installed: report?.avantReport?.activeSvdu5Installed,
            avantInactiveDsuDownloaded: report?.avantReport?.inactiveDsuDownloaded,
            avantInactiveDsuInstalled: report?.avantReport?.inactiveDsuInstalled,
            avantInactiveSvdu5Downloaded: report?.avantReport?.inactiveSvdu5Downloaded,
            avantInactiveSvdu5Installed: report?.avantReport?.inactiveSvdu5Installed,
            avantDsuInactiveDownloadAt100: report?.avantReport?.dsuInactiveDownloadAt100,
            avantDsuInactiveInstalledAt100: report?.avantReport?.dsuInactiveInstalledAt100,
            avantSvdu5InactiveDownloadAt100: report?.avantReport?.svdu5InactiveDownloadAt100,
            avantSvdu5InactiveInstalledAt100: report?.avantReport?.svdu5InactiveInstalledAt100,
            // avantOkToRemoveTclLoader: report?.avantReport?.okToRemoveTclLoader,
            avantActiveMonth: report?.avantReport?.activeMonth,
            // avantQualityCheck: report?.avantReport?.qualityCheck,
            // avantDepart: report?.avantReport?.depart,
            avantLoadPercent: report?.avantReport?.loadPercent,
            avantNotes: report?.avantReport?.notes,
            avantSeatsNotWorking: report?.avantReport?.seatsNotWorking,
            // avantWaitingForApproval: report?.avantReport?.waitingForApproval,
            // avantApproved: report?.avantReport?.approved,
            // avantDtdUser: report?.avantDtdReport?.user,
            avantDtdStation: report?.avantDtdReport?.station,
            //avantDtdTCLUsed: report?.avantDtdReport?.tclUsed,
            avantDtdDate: report?.avantDtdReport?.date && formatDate(report?.avantDtdReport?.date),
            avantDtdDtdSerialOn: report?.avantDtdReport?.dtdSerialOn,
            avantDtdDtdSerialOff: report?.avantDtdReport?.dtdSerialOff,
            avantDtdDtdMonth: report?.avantDtdReport?.dtdMonth,
            // avantDtdLoadPercent: report?.avantDtdReport?.loadPercent,
            // avantDtdNotes: report?.avantDtdReport?.notes,
            // avantDtdSeatsNotWorking: report?.avantDtdReport?.seatsNotWorking,
            // avantDtdWaitingForApproval: report?.avantDtdReport?.waitingForApproval,
            // avantDtdApproved: report?.avantDtdReport?.approved,
          },
        ]);
        break;
      case 'ATPDR':
        setDataToExport([
          {
            fin: report?.fin,
            mediaType: report?.mediaType,
            model: report?.model,
            currentStatus: report?.currentStatus,
            atpdrUser: report?.atpdrReport?.user,
            atpdrStation: report?.atpdrReport?.station,
            atpdrFlightNumber: report?.atpdrReport?.flightNumber,
            atpdrDisability: report?.atpdrReport?.disability,
            atpdrDate: report?.atpdrReport?.date,
            atpdrCaseNumberOn: report?.atpdrReport?.caseNumberOn,
            atpdrCaseNumberOff: report?.atpdrReport?.caseNumberOff,
            atpdrMonth: report?.atpdrReport?.month,
            atpdrLoadPercent: report?.atpdrReport?.loadPercent,
            atpdrNotes: report?.atpdrReport?.notes,
            atpdrWaitingForSubmission: report?.atpdrReport?.waitingForSubmission,
            atpdrWaitingForApproval: report?.atpdrReport?.waitingForApproval,
            atpdrApproved: report?.atpdrReport?.approved,
          },
        ]);
        break;
      default:
        setDataToExport([]);
        break;
    }
  }, [report]);

  // Based on the report type, set the report to approved
  const approveReport = () => {
    if (report?.i4500Report?.waitingForApproval) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          i4500Report: {
            ...report.i4500Report,
            waitingForApproval: false,
            approved: true,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });

      return;
    } else if (report?.gogoReport?.waitingForApproval) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          gogoReport: {
            ...report.gogoReport,
            waitingForApproval: false,
            approved: true,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });

      return;
    } else if (report?.avantReport?.waitingForApproval) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          avantReport: {
            ...report.avantReport,
            waitingForApproval: false,
            approved: true,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });

      return;
    } else if (report?.avantDtdReport?.waitingForApproval) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          avantDtdReport: {
            ...report.avantDtdReport,
            waitingForApproval: false,
            approved: true,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });

      return;
    } else if (report?.atpdrReport?.waitingForApproval) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          atpdrReport: {
            ...report.atpdrReport,
            waitingForApproval: false,
            approved: true,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });
    }
  };

  const unapproveReport = () => {
    if (report?.i4500Report?.approved) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          i4500Report: {
            ...report.i4500Report,
            waitingForApproval: true,
            approved: false,
          },
        })
        .then((res) => {
          console.log('unapproved');
          window.location.href = `/batch/${batchId}`;
        });
      return;
    } else if (report?.gogoReport?.approved) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          gogoReport: {
            ...report.gogoReport,
            waitingForApproval: true,
            approved: false,
          },
        })
        .then((res) => {
          console.log('approved');
          window.location.href = `/batch/${batchId}`;
        });
      return;
    } else if (report?.avantReport?.approved) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          avantReport: {
            ...report.avantReport,
            waitingForApproval: true,
            approved: false,
          },
        })
        .then((res) => {
          console.log('unapproved');
          window.location.href = `/batch/${batchId}`;
        });
      return;
    } else if (report?.avantDtdReport?.approved) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          avantDtdReport: {
            ...report.avantDtdReport,
            waitingForApproval: true,
            approved: false,
          },
        })
        .then((res) => {
          console.log('unapproved');
          window.location.href = `/batch/${batchId}`;
        });
      return;
    } else if (report?.atpdrReport?.approved) {
      axios
        .put(`/api/batch/${batchId}/report/${reportId}`, {
          ...report,
          atpdrReport: {
            ...report.atpdrReport,
            waitingForApproval: true,
            approved: false,
          },
        })
        .then((res) => {
          console.log('unapproved');
          window.location.href = `/batch/${batchId}`;
        });
    }
  };

  const clearData = () => {
    console.log('clearing', report);

    axios
      .put(`/api/batch/${batchId}/report/${reportId}`, {
        _id: report._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus: 'Untouched',
      })
      .then(() => {
        window.location.href = `/batch/${batchId}/report/${reportId}`;
      });
  };

  const confirmClearData = () => {
    Modal.confirm({
      title: 'Clear Report Data',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to clear this report data?',
      okText: 'Clear Data',
      cancelText: 'Cancel',
      onOk: clearData,
      okType: 'danger',
    });
  };

  return (
    <MainLayout>
      {viewReportsPermission ? (
        <>
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">
                {' '}
                <ArrowLeftOutlined
                  onClick={() => {
                    navigate(`/batch/${batchId}`);
                  }}
                />
                {'   '} Fin {report.fin} - Loading Details
              </Title>
              <span style={{ fontSize: '1.2rem' }}>Report #{report.numberInBatch}</span>
            </Col>
            <Col flex="auto"></Col>
            <Col className="buttons">
              {/* If the current role is NOT admin and the report is approved, lock any editing from happening */}
              {auth?.role !== 3 &&
              (report?.i4500Report?.approved ||
                report?.gogoReport?.approved ||
                report?.avantReport?.approved ||
                report?.avantDtdReport?.approved ||
                report?.atpdrReport?.approved) ? (
                <Tag color="green">Approved</Tag>
              ) : editReportsPermission && enterDataPermission ? (
                <>
                  <Button danger className="header-button-1" onClick={confirmClearData}>
                    Clear Data
                  </Button>
                  <Button
                    className="header-button-2"
                    type="primary"
                    disabled={isApproved}
                    onClick={() => setEditing(!editing)}
                  >
                    Edit Fields
                  </Button>
                </>
              ) : enterDataPermission && !editReportsPermission ? (
                <>
                  <Button
                    className="header-button-2"
                    type="primary"
                    disabled={isApproved}
                    onClick={() => setEditing(!editing)}
                  >
                    Edit Fields
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
          {report?.i4500Report?.approved ||
          report?.gogoReport?.approved ||
          report?.avantReport?.approved ||
          report?.avantDtdReport?.approved ||
          report?.atpdrReport?.approved ? (
            <Row style={{ marginBottom: '20px' }}>
              <Col flex="auto">
                <Tag color="green">Approved</Tag>
              </Col>
            </Row>
          ) : null}

          {report?.i4500Report?.waitingForSubmission ||
          report?.gogoReport?.waitingForSubmission ||
          report?.avantReport?.waitingForSubmission ||
          report?.avantDtdReport?.waitingForSubmission ||
          report?.atpdrReport?.waitingForSubmission ? (
            <Row style={{ marginBottom: '20px' }}>
              <Col flex="auto">
                <Tag color="blue">Waiting for submission</Tag>
              </Col>
            </Row>
          ) : null}
          <Row className="secondary-section" style={{ marginBottom: '20px' }}>
            <Col flex="auto"></Col>
            <Col className="buttons">
              {report.mediaType ? (
                <CSVLink filename={`Fin ${report.fin} report_export.csv`} data={dataToExport}>
                  <Button className="header-button-2">Export to CSV</Button>
                </CSVLink>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <DisplayMediaCard editing={editing} report={report} batchId={batchId} />
              {approveReportsPermission ? (
                waitingForApproval ? (
                  <Col flex="auto">
                    <Button style={{ marginTop: '2%' }} className="green-btn" onClick={approveReport}>
                      Approve Report
                    </Button>
                  </Col>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {/* Role 3 = admin */}
              {auth?.role === 3 &&
              isSuccess &&
              !waitingForApproval &&
              report?.currentStatus !== 'Untouched' &&
              isApproved ? (
                <Col flex="auto">
                  <Button style={{ marginTop: '2%' }} onClick={unapproveReport}>
                    Unapprove Report
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
