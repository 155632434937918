import React from 'react';
import { Card } from 'antd';
import { formatDate } from '../../utils/formatDate';

export default function atpdrDisplayCard({ report }) {
  return (
    <Card title={`ATPDR Loading Details`}>
      <div>
        <b>User</b>
        <p>{report?.atpdrReport?.user || '-'}</p>
      </div>
      <div>
        <b>Station</b>
        <p>{report?.atpdrReport?.station || '-'}</p>
      </div>
      <div>
        <b>Flight Number</b>
        <p>{report?.atpdrReport?.flightNumber || '-'}</p>
      </div>
      <div>
        <b>Disability</b>
        <p>{report?.atpdrReport?.disability || '-'}</p>
      </div>
      <div>
        <b>Date</b>
        <p>{report?.atpdrReport?.date ? report?.atpdrReport?.date.replace('T', ' ').split('.')[0] + ' GMT' : '-'}</p>
      </div>
      <div>
        <b>Case Number On</b>
        <p>{report?.atpdrReport?.caseNumberOn || '-'}</p>
      </div>
      <div>
        <b>Case Number Off</b>
        <p>{report?.atpdrReport?.caseNumberOff || '-'}</p>
      </div>
      <div>
        <b>Month</b>
        <p>{report?.atpdrReport?.month || '-'}</p>
      </div>
      {/* <div>
        <b>Load Percent</b>
        <p>{report?.atpdrReport?.loadPercent || '-'}</p>
      </div> */}
      <div>
        <b>Notes</b>
        <p>{report?.atpdrReport?.notes || '-'}</p>
      </div>
    </Card>
  );
}
