import { Modal, Form, Input } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from './DatePicker';

export default function CreateBatchModal(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    let values = form.getFieldsValue(true);

    let aircraftToBatch = props.aircraft.map((aircraft) => {
      return {
        fin: aircraft.fin,
        mediaType: aircraft.mediaType,
        model: aircraft.model,
      };
    });

    const batch = {
      batchName: values.batchName,
      monthToLoad: values.monthToLoad,
      billingMonth: values.billingMonth,
      activeMonth: values.activeMonth,
      aircraftReports: aircraftToBatch,
    };

    console.log('batch', batch);
    axios
      .post('/api/batch', batch)
      .then((res) => {
        props.onOk();
        navigate(`/batch/${res.data._id}`);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Modal title="Create Cycle" visible={props.visible} onOk={handleSubmit} onCancel={props.onCancel}>
      <Form form={form} layout="vertical" name="addAircraft" autoComplete="off">
        <Form.Item label="Name" name="batchName" rules={[{ required: true, message: 'Please add a Fin' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Month To Load"
          name="monthToLoad"
          rules={[{ required: true, message: 'Please add a month to load' }]}
        >
          <DatePicker picker="month" />
        </Form.Item>
        <Form.Item
          label="Billing Month"
          name="billingMonth"
          rules={[{ required: true, message: 'Please add a billing month' }]}
        >
          <DatePicker picker="month" />
        </Form.Item>
        <Form.Item
          label="Active Month"
          name="activeMonth"
          rules={[{ required: true, message: 'Please add an active month' }]}
        >
          <DatePicker picker="month" />
        </Form.Item>
      </Form>
      <p>{props.aircraft.length} Fins Added</p>
    </Modal>
  );
}
