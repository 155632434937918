import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import axios from 'axios';
import generator from 'generate-password-browser';

const { Option } = Select;

export default function AddUserModal(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  console.log(props?.userSelected);

  const handleSubmitAdd = () => {
    let randomPass = generator.generate({
      length: 10,
      numbers: true,
    });

    let values = form.getFieldsValue(true);
    console.log('values', values);

    axios
      .post(
        '/auth/signup',
        {
          email: values.email,
          password: randomPass,
          role: values.role,
        },
        { withCredentials: true }
      )
      .then((response) => {
        // Send team member an email with the login link + email + password
        if (!response.data.errmsg) {
          axios
            .post('/api/send-invite', {
              sendTo: values.email,
              randomPass,
            })
            .then(() => {
              // Show a confirmation modal
              inviteSuccess(values.email);

              // Re-update front end with new invited user
              if (props.fetchUserData) {
                props.fetchUserData();
              }
            });
        }
      });
  };

  const handleSubmitEdit = () => {
    let randomPass = generator.generate({
      length: 10,
      numbers: true,
    });

    let values = form.getFieldsValue(true);
    console.log('values', values);

    if (values.email === props.userSelected.email) {
      axios
        .patch(
          `/api/user/${props.userSelected._id}`,
          {
            role: values.role,
          },
          { withCredentials: true }
        )
        .then((response) => {
          console.log(response, 'response');
          props.onOk();
          messageApi.success('Data edited successfully!');
          props.fetchUserData();
          form.resetFields();
        });
    } else {
      axios
        .patch(
          `/api/user/${props.userSelected._id}`,
          {
            email: values.email,
            password: randomPass,
            role: values.role,
          },
          { withCredentials: true }
        )
        .then((response) => {
          // Send team member an email with the login link + email + password
          if (!response.data.errmsg) {
            axios
              .post('/api/send-invite', {
                sendTo: values.email,
                randomPass,
              })
              .then(() => {
                // Show a confirmation modal
                inviteSuccess(values.email);

                // Re-update front end with new invited user
                if (props.fetchUserData) {
                  props.fetchUserData();
                }
              });
          }
        });
    }
  };

  const handleSelectChange = (value) => {
    console.log(`selected ${value}`);
  };

  // Display the confirmation modal
  const inviteSuccess = (email) => {
    Modal.success({
      title: 'Invite a User',
      content: `An invitation has been sent to ${email}.`,
      onOk: () => {
        props.onOk();
        form.resetFields();
      },
    });
  };

  useEffect(() => {
    if (props?.userSelected) {
      form.setFieldValue('email', props.userSelected.email);
      form.setFieldValue('role', props.userSelected.role);
    }
  }, [form, props.userSelected]);

  return (
    <>
      <Modal
        title={props?.userSelected ? 'Edit User' : 'Add User'}
        visible={props.visible}
        onOk={props?.userSelected ? handleSubmitEdit : handleSubmitAdd}
        onCancel={props.onCancel}
      >
        <Form form={form} layout="vertical" name="addUser" autoComplete="off">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email',
              },
              {
                type: 'email',
                message: 'The input is not a valid email.',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role' }]}>
            <Select style={{ width: 120 }} onChange={handleSelectChange}>
              <Option value={3}>Admin</Option>
              <Option value={2}>Station Manager</Option>
              <Option value={1}>Client</Option>
              <Option value={0}>User</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </>
  );
}
