import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Alert } from 'antd';
import axios from 'axios';

const { Option } = Select;

export default function AddMediaReportModal(props) {
  const [form] = Form.useForm();
  const [aircraft, setAircraft] = useState([]);
  const [aircraftFromSelectedMediaType, setAircraftFromSelectedMediaType] = useState([]);
  const [finExistsError, setFinExistsError] = useState('');

  // Get all aircraft from the database
  useEffect(() => {
    axios
      .get('/api/aircraft')
      .then((res) => {
        setAircraft(res.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  // When the media type changes, change aircraftFromSelectedMediaType to correspond
  const handleMediaTypeChange = (value) => {
    console.log(`selected ${value}`);
    let result = aircraft.filter((plane) => plane.mediaType === value);
    setAircraftFromSelectedMediaType(result);
  };

  const handleFinChange = (value) => {
    let duplicateFound = false;

    // If the fin already exists in the report, display an error
    props?.batch?.aircraftReports.forEach((report) => {
      if (value == report.fin) {
        duplicateFound = true;
        setFinExistsError('Fin already exists in report. Please choose another');
      }
    });

    // If no duplicate found, reset the error message
    if (!duplicateFound) setFinExistsError('');
  };

  const handleSubmit = () => {
    let values = form.getFieldsValue(true);
    console.log('values', values);

    // Get the original aircraft object that has all properties we need
    let foundAircraft = aircraftFromSelectedMediaType.filter((aircraft) => aircraft.fin == values.fin);
    console.log('foundAircraft', foundAircraft);

    let { fin, mediaType, model } = foundAircraft[0];
    console.log('fin, mediaType, model', fin, mediaType, model);

    // If all properties found and fin doesn't exist already in the report, proceed with submission
    if (fin && mediaType && model && !finExistsError) {
      axios
        .post(`/api/batch/${props.batch._id}/report`, {
          fin,
          mediaType,
          model,
        })
        .then((res) => {
          window.location.href = `/batch/${props.batch._id}`;
        });
    }
  };

  return (
    <Modal
      title="Add Report from Media Type"
      visible={props.visible}
      onOk={handleSubmit}
      onCancel={props.onCancel}
      width={'66%'}
    >
      <Form form={form} layout="vertical" name="addAircraft" autoComplete="off">
        <Form.Item
          label="Media Type"
          name="mediaType"
          rules={[
            {
              required: true,
              message: 'Please input a media type',
            },
          ]}
        >
          <Select
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Select Media Type"
            onChange={handleMediaTypeChange}
          >
            <Option key="i4500" value="i4500">
              i4500
            </Option>
            <Option key="GoGo" value="GoGo">
              GoGo
            </Option>
            <Option key="Avant" value="Avant">
              Avant
            </Option>
            <Option key="ATPDR" value="ATPDR">
              ATPDR
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={`Fins from selected media type`}
          name="fin"
          rules={[
            {
              required: true,
              message: 'Please input a fin',
            },
          ]}
        >
          {aircraftFromSelectedMediaType.length > 0 ? (
            <Select
              showSearch
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Select Fin"
              onChange={handleFinChange}
            >
              {aircraftFromSelectedMediaType.map((aircraft) => (
                <Option key={aircraft.fin} value={aircraft.fin}>
                  Fin: {aircraft.fin}
                </Option>
              ))}
            </Select>
          ) : (
            <i>Please select a media type first</i>
          )}
        </Form.Item>
      </Form>
      {finExistsError ? (
        <>
          <Alert message={finExistsError} type="error" />
        </>
      ) : null}
    </Modal>
  );
}
