import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Row, Col } from 'antd';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import MediaShippingTable from '../components/MediaShippingTable';
import AddShippingModal from '../components/AddShippingModal';
import { hasPermission } from '../helpers';

const { Title } = Typography;

export default function MediaShippingScreen() {
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shipping, setShipping] = useState([]);
  const [viewShippingPermission, setViewShippingPermission] = useState(false);

  useEffect(() => {
    axios
      .get('/api/shipping')
      .then((res) => {
        setShipping(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (roles && auth) {
      setViewShippingPermission(hasPermission('viewShipping', auth.role, roles));
    }
  }, [roles, auth]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    axios
      .get('/api/shipping')
      .then((res) => {
        setShipping(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <MainLayout>
      {viewShippingPermission ? (
        <>
          <AddShippingModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} />
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">Media Shipping</Title>
            </Col>
            <Col flex="auto"></Col>
            <Col className="buttons">
              <Button type="primary" onClick={showModal}>
                Add New
              </Button>
            </Col>
          </Row>
          <Row className="secondary-section">
            <Col flex="auto"></Col>
            <Col className="buttons">
              <CSVLink filename={'shipping_export.csv'} data={shipping.map(({ _id, __v, ...item }) => item)}>
                <Button className="header-button-2">Export to CSV</Button>
              </CSVLink>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <MediaShippingTable data={shipping} />
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
