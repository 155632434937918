import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Row, Col } from 'antd';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import UsersTable from '../components/UsersTable';
import MainLayout from './layouts/MainLayout';
import AddUserModal from '../components/AddUserModal';
import { hasPermission } from '../helpers';

const { Title } = Typography;

export default function UsersScreen() {
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [addUsersPermission, setAddUsersPermission] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (roles && auth) {
      setAddUsersPermission(hasPermission('addUsers', auth.role, roles));
    }
  }, [roles, auth]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    axios.get('/api/user', { withCredentials: true }).then((res) => {
      setData(res.data);
    });
  };

  const showUserModal = () => {
    setIsUserModalVisible(true);
  };

  const handleUserOk = () => {
    setIsUserModalVisible(false);
  };

  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };

  console.log('users', data);

  return (
    <MainLayout>
      {addUsersPermission ? (
        <>
          <AddUserModal
            visible={isUserModalVisible}
            onOk={handleUserOk}
            onCancel={handleUserCancel}
            fetchUserData={fetchUserData}
          />
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">Users</Title>
            </Col>
            <Col flex="auto"></Col>
            <Col className="buttons">
              <Button className="header-button-2" type="primary" onClick={showUserModal}>
                Add User
              </Button>
            </Col>
          </Row>
          <Row className="secondary-section">
            <Col flex="auto"></Col>
            <Col className="buttons">
              <CSVLink filename={'users_export.csv'} data={data.map(({ _id, password, __v, ...item }) => item)}>
                <Button className="header-button-2">Export to CSV</Button>
              </CSVLink>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <UsersTable fetchUserData={fetchUserData} data={data} />
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
