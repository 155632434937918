import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Select, Button } from 'antd';
import DatePicker from '../DatePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const SAVE_AFTER_MS = 1000;

export default function GoGoEditCard({ report, batchId }) {
  const auth = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const autoSaveTimer = useRef();

  const [readyToSave, setReadyToSave] = useState(false);
  const [fields, setFields] = useState({});

  console.log(fields, 'fields');

  useEffect(() => {
    if (sessionStorage?.form) {
      if (JSON.parse(sessionStorage?.form)?.reportId === report._id) {
        let sessionFields = JSON.parse(sessionStorage.form);
        console.log('sessionFields', sessionFields);

        form.setFieldsValue({
          station: sessionFields?.station || '',
          serialNumberOn: sessionFields?.serialNumberOn || '',
          serialNumberOff: sessionFields?.serialNumberOff || '',
          loadPercent: sessionFields?.loadPercent || '',
          batch: sessionFields?.batch || '',
          notes: sessionFields?.notes || '',
        });

        if (sessionFields?.date) {
          form.setFieldsValue({
            date: dayjs(sessionFields?.date) || '',
          });
        }
      }
    } else {
      form.setFieldsValue({
        station: report?.gogoReport?.station || '',
        serialNumberOn: report?.gogoReport?.serialNumberOn || '',
        serialNumberOff: report?.gogoReport?.serialNumberOff || '',
        loadPercent: report?.gogoReport?.loadPercent || '',
        batch: report?.gogoReport?.batch || '',
        notes: report?.gogoReport?.notes || '',
      });

      if (report?.gogoReport?.date) {
        form.setFieldsValue({
          date: dayjs(report?.gogoReport?.date) || '',
        });
      }
    }
  }, [report]);

  // Submit values to database
  const onFinish = (values) => {
    console.log('Success:', values);

    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          values?.loadPercent > 0 && values?.loadPercent < 100
            ? 'Partial Load'
            : values?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        gogoReport: {
          user: auth?.email,
          station: values?.station,
          date: values?.date,
          fin: report?.fin,
          serialNumberOn: values?.serialNumberOn,
          serialNumberOff: values?.serialNumberOff,
          loadPercent: values?.loadPercent,
          batch: values?.batch,
          notes: values?.notes,
          waitingForApproval: true,
          waitingForSubmission: false,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    console.log('onFieldsChange', changedFields, allFields);

    let sessionSave = {
      reportId: report._id,
      station: allFields[0].value,
      date: allFields[1].value,
      serialNumberOn: allFields[2].value,
      serialNumberOff: allFields[3].value,
      batch: allFields[4].value,
      notes: allFields[5].value,
    };

    setFields(sessionSave);
    setReadyToSave(true);

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setIsSaving(true);

    autoSaveTimer.current = setTimeout(() => {
      sessionStorage.setItem('form', JSON.stringify(sessionSave));
      clearTimeout(autoSaveTimer.current);
      setIsSaving(false);
    }, SAVE_AFTER_MS);
  };

  const onSave = () => {
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          fields?.loadPercent > 0 && fields?.loadPercent < 100
            ? 'Partial Load'
            : fields?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        gogoReport: {
          user: auth?.email,
          station: fields?.station,
          date: fields?.date,
          fin: report?.fin,
          serialNumberOn: fields?.serialNumberOn,
          serialNumberOff: fields?.serialNumberOff,
          loadPercent: fields?.loadPercent,
          batch: fields?.batch,
          notes: fields?.notes,
          waitingForSubmission: true,
          waitingForApproval: false,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <Card title={`Gogo Loading Details - Edit`}>
      <Form
        form={form}
        name="gogoForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Station" name="station">
              <Select placeholder="Select a station" allowClear>
                <Option value="YUL">YUL</Option>
                <Option value="YVR">YVR</Option>
                <Option value="YYC">YYC</Option>
                <Option value="YYZ">YYZ</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Date" name="date">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Serial Number On" name="serialNumberOn">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Serial Number Off" name="serialNumberOff">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={12} xs={24}>
            <Form.Item label="Batch #" name="batch">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {/* <Col xl={11} xs={24}>
            <Form.Item
              label="Load %"
              name="loadPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
              ]}
            >
              <InputNumber min={0} max={100} step={100} />
            </Form.Item>
          </Col> */}
          {/* <Col flex="auto"></Col> */}
          <Col xl={12} xs={24}>
            <Form.Item label="Notes" name="notes">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="submit-button">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

          <Button type="primary" onClick={onSave} disabled={!readyToSave} style={{ marginLeft: 20 }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
