import { Modal, Card, Table } from 'antd';
import React, { useState, useEffect } from 'react';

export default function ContentLoadingDetailsModal({ aircraftReports, visible, handleSubmit, handleCancel }) {
  const [i4500Details, setI4500Details] = useState([]);
  const [gogoDetails, setGogoDetails] = useState([]);
  const [avantDetails, setAvantDetails] = useState([]);
  const [atpdrDetails, setAtpdrDetails] = useState([]);

  const [i4500Formatted, setI4500Formatted] = useState([]);
  const [gogoFormatted, setGogoFormatted] = useState([]);
  const [avantFormatted, setAvantFormatted] = useState([]);
  const [atpdrFormatted, setAtpdrFormatted] = useState([]);

  // Per report, generate an object that looks like this:
  useEffect(() => {
    if (aircraftReports) {
      aircraftReports.forEach((aircraftReport) => {
        switch (aircraftReport?.mediaType) {
          case 'i4500':
            setI4500Details((prev) => [
              ...prev,
              {
                model: aircraftReport?.model,
                percentLoaded: aircraftReport?.i4500Report?.loadPercent || 0,
              },
            ]);
            break;
          case 'GoGo':
            setGogoDetails((prev) => [
              ...prev,
              {
                model: aircraftReport?.model,
                percentLoaded: aircraftReport?.gogoReport?.loadPercent || 0,
              },
            ]);
            break;
          case 'Avant':
            setAvantDetails((prev) => [
              ...prev,
              {
                model: aircraftReport?.model,
                percentLoaded:
                  aircraftReport?.avantReport?.loadPercent || aircraftReport?.avantDtdReport?.loadPercent || 0,
              },
            ]);
            break;
          case 'ATPDR':
            setAtpdrDetails((prev) => [
              ...prev,
              {
                model: aircraftReport?.model,
                percentLoaded: aircraftReport?.atpdrReport?.loadPercent || 0,
              },
            ]);
            break;
          default:
            break;
        }
      });
    }
  }, [aircraftReports]);

  // Sort the details into the correct format
  useEffect(() => {
    if (i4500Details) {
      let result = i4500Details.reduce((a, { model, percentLoaded }) => {
        let key = `${model}`;
        a[key] = a[key] || { model, aircraftLoaded: 0, aircraftToLoad: 0, totalPercentLoaded: 0 };
        a[key].aircraftToLoad++;
        a[key].totalPercentLoaded += percentLoaded;
        if (percentLoaded >= 100) {
          a[key].aircraftLoaded++;
        }
        return a;
      }, {});
      setI4500Formatted(Object.values(result));
    }
    if (gogoDetails) {
      let result = gogoDetails.reduce((a, { model, percentLoaded }) => {
        let key = `${model}`;
        a[key] = a[key] || { model, aircraftLoaded: 0, aircraftToLoad: 0, totalPercentLoaded: 0 };
        a[key].aircraftToLoad++;
        a[key].totalPercentLoaded += percentLoaded;
        if (percentLoaded >= 100) {
          a[key].aircraftLoaded++;
        }
        return a;
      }, {});
      setGogoFormatted(Object.values(result));
    }
    if (avantDetails) {
      let result = avantDetails.reduce((a, { model, percentLoaded }) => {
        let key = `${model}`;
        a[key] = a[key] || { model, aircraftLoaded: 0, aircraftToLoad: 0, totalPercentLoaded: 0 };
        a[key].aircraftToLoad++;
        a[key].totalPercentLoaded += percentLoaded;
        if (percentLoaded >= 100) {
          a[key].aircraftLoaded++;
        }
        return a;
      }, {});
      setAvantFormatted(Object.values(result));
    }
    if (atpdrDetails) {
      let result = atpdrDetails.reduce((a, { model, percentLoaded }) => {
        let key = `${model}`;
        a[key] = a[key] || { model, aircraftLoaded: 0, aircraftToLoad: 0, totalPercentLoaded: 0 };
        a[key].aircraftToLoad++;
        a[key].totalPercentLoaded += percentLoaded;
        if (percentLoaded >= 100) {
          a[key].aircraftLoaded++;
        }
        return a;
      }, []);
      setAtpdrFormatted(Object.values(result));
    }
  }, [i4500Details, gogoDetails, avantDetails, atpdrDetails]);

  const columns = [
    {
      title: 'Aircraft Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Aircraft to Load',
      dataIndex: 'aircraftToLoad',
      key: 'aircraftToLoad',
    },
    {
      title: 'Aircraft Loaded',
      dataIndex: 'aircraftLoaded',
      key: 'aircraftLoaded',
    },
    {
      title: 'Percent Loaded',
      dataIndex: 'totalPercentLoaded',
      key: 'totalPercentLoaded',
      render: (text, record) => {
        return `${record.totalPercentLoaded / record.aircraftToLoad}%`;
      },
    },
  ];

  return (
    <Modal
      title="Content Loading Report - Approved Reports"
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      width={'80%'}
    >
      <Card title="Approved Reports Overview">
        <b>i4500</b>
        {i4500Formatted.length > 0 ? (
          <div>
            <Table
              dataSource={i4500Formatted}
              columns={columns}
              rowKey="model"
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
            />
          </div>
        ) : (
          <p>No data</p>
        )}
        <b>GoGo</b>
        {gogoFormatted.length > 0 ? (
          <div>
            <Table
              dataSource={gogoFormatted}
              columns={columns}
              rowKey="model"
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
            />
          </div>
        ) : (
          <p>No data</p>
        )}
        <b>Avant / Avant DTD</b>
        {avantFormatted.length > 0 ? (
          <div>
            <Table
              dataSource={avantFormatted}
              columns={columns}
              rowKey="model"
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
            />
          </div>
        ) : (
          <p>No data</p>
        )}
        <b>ATPDR</b>
        {atpdrFormatted.length > 0 ? (
          <div>
            <Table
              dataSource={atpdrFormatted}
              columns={columns}
              rowKey="model"
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
            />
          </div>
        ) : (
          <p>No data</p>
        )}
      </Card>
    </Modal>
  );
}
