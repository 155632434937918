import { Modal, Form, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const { Option } = Select;

// Allow adding and removing fins from this report
export default function EditBatchFinsModal(props) {
  const [form] = Form.useForm();
  const [aircraft, setAircraft] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);

  // Get all aircraft from the database
  useEffect(() => {
    axios
      .get('/api/aircraft')
      .then((res) => {
        setAircraft(res.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  //
  useEffect(() => {
    if (props?.batch?.aircraftReports) {
      let defaultVals = [];

      props.batch.aircraftReports.map((report) => {
        defaultVals.push(report.fin);
      });

      setDefaultValues(defaultVals);
    }
  }, [props.batch, aircraft]);

  const handleAddChange = (value) => {
    console.log(`add selected ${value}`);
  };

  const handleRemoveChange = (value) => {
    console.log(`remove selected ${value}`);
  };

  // For all submitted values
  // If the fin already exists in this batch, do nothing
  // If the fin existed in the batch and is now removed, remove it from the batch,
  // If the fin is new, add it to the batch
  const handleSubmit = () => {
    let values = form.getFieldsValue(true);
    console.log('fin submit values', values);

    let addRequest = [];
    let removeRequest = [];

    // Add new fins to the batch
    let finsToAdd = values.finsToAdd || [];
    let finsToRemove = values.finsToRemove || [];

    aircraft.map((plane) => {
      if (finsToAdd.includes(plane.fin)) {
        addRequest.push({
          fin: plane.fin,
          mediaType: plane.mediaType,
          model: plane.model,
        });
      }
    });

    props?.batch?.aircraftReports.map((report) => {
      if (finsToRemove.includes(report.fin)) {
        removeRequest.push(report._id);
      }
    });

    if (addRequest.length > 0 && removeRequest.length > 0) {
      addRequest.map((obj) => {
        axios
          .post(`/api/batch/${props.batch._id}/report`, obj)
          .then(() => {
            removeRequest.map((id) => {
              console.log('removing', id);
              axios
                .delete(`/api/batch/${props.batch._id}/report/${id}`)
                .then(() => {
                  props.onOk();
                  window.location.href = `/batch/${props.batch._id}`;
                })
                .catch((err) => {
                  console.log('err', err);
                });
            });
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
    } else if (addRequest.length > 0 && removeRequest.length === 0) {
      addRequest.map((obj) => {
        axios
          .post(`/api/batch/${props.batch._id}/report`, obj)
          .then(() => {
            props.onOk();
            window.location.href = `/batch/${props.batch._id}`;
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
    } else if (addRequest.length === 0 && removeRequest.length > 0) {
      removeRequest.map((id) => {
        console.log('removing', id);
        axios
          .delete(`/api/batch/${props.batch._id}/report/${id}`)
          .then(() => {
            props.onOk();
            window.location.href = `/batch/${props.batch._id}`;
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
    }
  };

  return (
    <Modal
      title="Add/Remove Fins from Batch"
      visible={props.visible}
      onOk={handleSubmit}
      onCancel={props.onCancel}
      width={'66%'}
    >
      <Form form={form} layout="vertical" name="addAircraft" autoComplete="off" initialValues={{ fins: defaultValues }}>
        <Form.Item label="Fins to Add" name="finsToAdd">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Add or remove fins until complete"
            onChange={handleAddChange}
          >
            {aircraft.map((aircraft) => (
              <Option key={aircraft._id} value={aircraft.fin}>
                Fin: {aircraft.fin}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Fins to Remove" name="finsToRemove">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Add or remove fins until complete"
            onChange={handleRemoveChange}
          >
            {aircraft.map((aircraft) => (
              <Option key={aircraft._id} value={aircraft.fin}>
                Fin: {aircraft.fin}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
