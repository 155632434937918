import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import GoGoDisplayCard from './media_cards/GoGoDisplayCard';
import GoGoEditCard from './media_cards/GoGoEditCard';
import AtpdrDisplayCard from './media_cards/AtpdrDisplayCard';
import AtpdrEditCard from './media_cards/AtpdrEditCard';
import I4500DisplayCard from './media_cards/I4500DisplayCard';
import I4500EditCard from './media_cards/I4500EditCard';
import AvantDisplayCard from './media_cards/AvantDisplayCard';
import AvantEditCard from './media_cards/AvantEditCard';
import AvantDtdDisplayCard from './media_cards/AvantDtdDisplayCard';
import AvantDtdEditCard from './media_cards/AvantDtdEditCard';

export default function DisplayMedia({ report, editing, batchId }) {
  const [showAvantForm, setShowAvantForm] = useState(false);
  const [showAvantDtdForm, setShowAvantDtdForm] = useState(false);

  const renderCard = () => {
    switch (report?.mediaType) {
      case 'GoGo':
        return editing ? <GoGoEditCard report={report} batchId={batchId} /> : <GoGoDisplayCard report={report} />;
      case 'ATPDR':
        return editing ? <AtpdrEditCard report={report} batchId={batchId} /> : <AtpdrDisplayCard report={report} />;
      case 'i4500':
        return editing ? <I4500EditCard report={report} batchId={batchId} /> : <I4500DisplayCard report={report} />;
      case 'Avant':
        console.log('avant', report);
        if (!report.avantReport && !report.avantDtdReport) {
          // Allow selection between avant and avant DTD report
          return showAvantForm ? (
            <>
              <AvantEditCard report={report} batchId={batchId} />
              <br />
              <Button onClick={() => setShowAvantForm(false)}>Cancel</Button>
            </>
          ) : showAvantDtdForm ? (
            <>
              <AvantDtdEditCard report={report} batchId={batchId} />
              <br />
              <Button onClick={() => setShowAvantDtdForm(false)}>Cancel</Button>
            </>
          ) : (
            <>
              <Row>
                <Col span={24}></Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={() => {
                      setShowAvantForm(true);
                      setShowAvantDtdForm(false);
                    }}
                  >
                    New Avant Report
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      setShowAvantForm(false);
                      setShowAvantDtdForm(true);
                    }}
                  >
                    New Avant DTD Report
                  </Button>
                </Col>{' '}
                <Col flex="auto"></Col>
              </Row>
            </>
          );
        } else if (report.avantReport && !report.avantDtdReport) {
          // Show avant report and edit cards
          return editing ? <AvantEditCard report={report} batchId={batchId} /> : <AvantDisplayCard report={report} />;
        } else if (!report.avantReport && report.avantDtdReport) {
          // Show dtd report and edit cards
          return editing ? (
            <AvantDtdEditCard report={report} batchId={batchId} />
          ) : (
            <AvantDtdDisplayCard report={report} />
          );
        } else {
          // This should never be the case, indicates a bug
          return <>Both Avant and Avant DTD reports detected</>;
        }
      default:
        return <>Unknown Media Type</>;
    }
  };

  return <>{renderCard()}</>;
}
