import { Modal, Form, Input, Select, message, Button } from 'antd';
import React, { useState } from 'react';
import axios from 'axios';

export default function RemoveUserModal(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/api/user/${props.userSelected._id}`);
      messageApi.success('User removed');
      props.fetchUserData();
      props.onCancel();
    } catch (error) {
      messageApi.success('Please, check your connection and try again');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={'Remove User'}
        visible={props.visible}
        footer={[
          <Button type="primary" loading={isLoading} onClick={handleRemove}>
            Ok
          </Button>,
        ]}
        onCancel={props.onCancel}
      >
        Are you sure do you want to remove this user ({props?.userSelected?.email})?
      </Modal>

      {contextHolder}
    </>
  );
}
