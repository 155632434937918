import React, { useEffect, useState } from 'react';
import { Table, Tag, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function BatchReportsTable(props) {
  const navigate = useNavigate();
  const [finFilters, setFinFilters] = useState([]);

  // Set the fin filters
  useEffect(() => {
    if (props?.data?.length) {
      props.data.map((report) => {
        setFinFilters((prev) => [...prev, { text: report.fin, value: report.fin }]);
      });
    }
  }, [props.data]);

  const columns = [
    {
      title: 'Fin',
      dataIndex: 'fin',
      key: 'fin',
      filters: finFilters,
      filterSearch: true,
      onFilter: (value, record) => record.fin === value,
      render: (text, record) => {
        return (
          <>
            <a href="#/" onClick={() => navigate(`/batch/${props.batchId}/report/${record._id}`)}>
              {text}
            </a>
            <br />
            <>Report #{record.numberInBatch}</>
          </>
        );
      },
    },
    {
      title: 'Media Type',
      dataIndex: 'mediaType',
      key: 'mediaType',
      filters: [
        { text: 'i4500', value: 'i4500' },
        { text: 'ATPDR', value: 'ATPDR' },
        { text: 'Avant', value: 'Avant' },
        { text: 'GoGo', value: 'GoGo' },
      ],
      onFilter: (value, record) => record.mediaType === value,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Percent Loaded',
      dataIndex: 'loadPercent',
      key: 'loadPercent',
      render: (text, record) => {
        switch (record?.mediaType) {
          case 'i4500':
            return record?.i4500Report?.loadPercent || 0;

          case 'GoGo':
            return record?.gogoReport?.loadPercent || 0;

          case 'Avant':
            return record?.avantReport?.loadPercent || record?.avantDtdReport?.loadPercent || 0;

          case 'ATPDR':
            return record?.atpdrReport?.loadPercent || 0;

          default:
            return '-';
        }
      },
    },
    {
      title: 'Station',
      dataIndex: 'station',
      key: 'station',
      render: (text, record) => {
        switch (record?.mediaType) {
          case 'i4500':
            return record?.i4500Report?.station || '';

          case 'GoGo':
            return record?.gogoReport?.station || '';

          case 'Avant':
            return record?.avantReport?.station || record?.avantDtdReport?.station || '';

          case 'ATPDR':
            return record?.atpdrReport?.loadPercent?.station || '';

          default:
            return '-';
        }
      },
    },
    {
      title: 'Current Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      filters: [
        { text: 'Untouched', value: 'Untouched' },
        { text: 'Partial Load', value: 'Partial Load' },
        { text: 'Fully Loaded', value: 'Fully Loaded' },
      ],
      onFilter: (value, record) => record.currentStatus === value,
      render: (text, record) => {
        switch (text) {
          case 'Untouched':
            return <Tag color="orange">{text}</Tag>;
          case 'Partial Load':
            return <Tag color="blue">{text}</Tag>;
          case 'Fully Loaded':
            return <Tag color="green">{text}</Tag>;
          case 'Not Loaded':
            return <Tag color="purple">Not Loaded - Saved</Tag>;
          default:
            return <Tag>-</Tag>;
        }
      },
    },
    {
      title: 'Approved?',
      dataIndex: '',
      key: '',
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => {
        if (value === true) {
          return (
            record?.i4500Report?.approved ||
            record?.gogoReport?.approved ||
            record?.avantReport?.approved ||
            record?.avantDtdReport?.approved ||
            record?.atpdrReport?.approved
          );
        } else {
          return !(
            record?.i4500Report?.approved ||
            record?.gogoReport?.approved ||
            record?.avantReport?.approved ||
            record?.avantDtdReport?.approved ||
            record?.atpdrReport?.approved
          );
        }

        // switch (record?.mediaType) {
        //   case 'i4500':
        //     return record?.i4500Report?.approved === value || record;
        //   case 'GoGo':
        //     return record?.gogoReport?.approved === value || record;
        //   case 'Avant':
        //     return record?.avantReport?.approved || record?.avantDtdReport?.approved === value || record;
        //   case 'ATPDR':
        //     return record?.atpdrReport?.approved === value || record;
        //   default:
        //     return false;
        // }
      },
      render: (text, record) => {
        switch (record?.mediaType) {
          case 'i4500':
            return record?.i4500Report?.approved ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
          case 'GoGo':
            return record?.gogoReport?.approved ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
          case 'Avant':
            return record?.avantReport?.approved || record?.avantDtdReport?.approved ? (
              <Tag color="green">Yes</Tag>
            ) : (
              <Tag color="red">No</Tag>
            );
          case 'ATPDR':
            return record?.atpdrReport?.approved ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
          default:
            return '-';
        }
      },
    },
  ];

  return props.data !== [] ? (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey="_id"
      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
    />
  ) : (
    <Spin />
  );
}
