import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Select, Button, Checkbox } from 'antd';
import DatePicker from '../DatePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const SAVE_AFTER_MS = 1000;

export default function AvantEditCard({ report, batchId }) {
  const auth = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const autoSaveTimer = useRef();

  const [readyToSave, setReadyToSave] = useState(false);
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (sessionStorage?.form) {
      if (JSON.parse(sessionStorage?.form)?.reportId === report._id) {
        let sessionFields = JSON.parse(sessionStorage.form);

        form.setFieldsValue({
          station: sessionFields?.station || '',
          tclUsed: sessionFields?.tclUsed || '',
          loadTimeMins: sessionFields?.loadTimeMins || '',
          totalTimeMins: sessionFields?.totalTimeMins || '',
          cil: sessionFields?.cil || '',
          dtdOn: sessionFields?.dtdOn || '',
          dtdOff: sessionFields?.dtdOff || '',
          dsu1: sessionFields?.dsu1 || '',
          dsu2: sessionFields?.dsu2 || '',
          arrive24h: sessionFields?.arrive24h || '',
          powerTCL: sessionFields?.powerTCL || '',
          systemReady: sessionFields?.systemReady || '',
          downloadStart: sessionFields?.downloadStart || '',
          activeDsuDownloaded: sessionFields?.activeDsuDownloaded || '',
          activeDsuInstalled: sessionFields?.activeDsuInstalled || '',
          activeSvdu5Downloaded: sessionFields?.activeSvdu5Downloaded || '',
          activeSvdu5Installed: sessionFields?.activeSvdu5Installed || '',
          inactiveDsuDownloaded: sessionFields?.inactiveDsuDownloaded || '',
          inactiveDsuInstalled: sessionFields?.inactiveDsuInstalled || '',
          inactiveSvdu5Downloaded: sessionFields?.inactiveSvdu5Downloaded || '',
          inactiveSvdu5Installed: sessionFields?.inactiveSvdu5Installed || '',
          dsuInactiveDownloadAt100: sessionFields?.dsuInactiveDownloadAt100 || '',
          dsuInactiveInstalledAt100: sessionFields?.dsuInactiveInstalledAt100 || '',
          svdu5InactiveDownloadAt100: sessionFields?.svdu5InactiveDownloadAt100 || '',
          svdu5InactiveInstalledAt100: sessionFields?.svdu5InactiveInstalledAt100 || '',
          okToRemoveTclLoader: sessionFields?.okToRemoveTclLoader || '',
          activeMonth: sessionFields?.activeMonth || '',
          qualityCheck: sessionFields?.qualityCheck || '',
          depart: sessionFields?.depart || '',
          loadPercent: sessionFields?.loadPercent || '',
          notes: sessionFields?.notes || '',
          seatsNotWorking: sessionFields?.seatsNotWorking || '',
        });

        if (sessionFields?.date) {
          form.setFieldsValue({
            date: dayjs(sessionFields?.date) || '',
          });
        }
      }
    } else {
      form.setFieldsValue({
        station: report?.avantReport?.station || '',
        tclUsed: report?.avantReport?.tclUsed || '',
        loadTimeMins: report?.avantReport?.loadTimeMins || '',
        totalTimeMins: report?.avantReport?.totalTimeMins || '',
        cil: report?.avantReport?.cil || '',
        dtdOn: report?.avantReport?.dtdOn || '',
        dtdOff: report?.avantReport?.dtdOff || '',
        dsu1: report?.avantReport?.dsu1 || '',
        dsu2: report?.avantReport?.dsu2 || '',
        arrive24h: report?.avantReport?.arrive24h || '',
        powerTCL: report?.avantReport?.powerTCL || '',
        systemReady: report?.avantReport?.systemReady || '',
        downloadStart: report?.avantReport?.downloadStart || '',
        activeDsuDownloaded: report?.avantReport?.activeDsuDownloaded || '',
        activeDsuInstalled: report?.avantReport?.activeDsuInstalled || '',
        activeSvdu5Downloaded: report?.avantReport?.activeSvdu5Downloaded || '',
        activeSvdu5Installed: report?.avantReport?.activeSvdu5Installed || '',
        inactiveDsuDownloaded: report?.avantReport?.inactiveDsuDownloaded || '',
        inactiveDsuInstalled: report?.avantReport?.inactiveDsuInstalled || '',
        inactiveSvdu5Downloaded: report?.avantReport?.inactiveSvdu5Downloaded || '',
        inactiveSvdu5Installed: report?.avantReport?.inactiveSvdu5Installed || '',
        dsuInactiveDownloadAt100: report?.avantReport?.dsuInactiveDownloadAt100 || '',
        dsuInactiveInstalledAt100: report?.avantReport?.dsuInactiveInstalledAt100 || '',
        svdu5InactiveDownloadAt100: report?.avantReport?.svdu5InactiveDownloadAt100 || '',
        svdu5InactiveInstalledAt100: report?.avantReport?.svdu5InactiveInstalledAt100 || '',
        okToRemoveTclLoader: report?.avantReport?.okToRemoveTclLoader || '',
        activeMonth: report?.avantReport?.activeMonth || '',
        qualityCheck: report?.avantReport?.qualityCheck || '',
        depart: report?.avantReport?.depart || '',
        loadPercent: report?.avantReport?.loadPercent || '',
        notes: report?.avantReport?.notes || '',
        seatsNotWorking: report?.avantReport?.seatsNotWorking || '',
      });

      if (report?.avantReport?.date) {
        form.setFieldsValue({
          date: dayjs(report?.avantReport?.date) || '',
        });
      }
    }
  }, [report]);

  const onFinish = (values) => {
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          values?.loadPercent > 0 && values?.loadPercent < 100
            ? 'Partial Load'
            : values?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        avantReport: {
          user: auth?.email,
          station: values?.station,
          tclUsed: values?.tclUsed,
          date: values?.date,
          loadTimeMins: values?.loadTimeMins,
          totalTimeMins: values?.totalTimeMins,
          cil: values?.cil,
          dtdOn: values?.dtdOn,
          dtdOff: values?.dtdOff,
          dsu1: values?.dsu1,
          dsu2: values?.dsu2,
          arrive24h: values?.arrive24h,
          powerTCL: values?.powerTCL,
          systemReady: values?.systemReady,
          downloadStart: values?.downloadStart,
          activeDsuDownloaded: values?.activeDsuDownloaded,
          activeDsuInstalled: values?.activeDsuInstalled,
          activeSvdu5Downloaded: values?.activeSvdu5Downloaded,
          activeSvdu5Installed: values?.activeSvdu5Installed,
          inactiveDsuDownloaded: values?.inactiveDsuDownloaded,
          inactiveDsuInstalled: values?.inactiveDsuInstalled,
          inactiveSvdu5Downloaded: values?.inactiveSvdu5Downloaded,
          inactiveSvdu5Installed: values?.inactiveSvdu5Installed,
          dsuInactiveDownloadAt100: values?.dsuInactiveDownloadAt100,
          dsuInactiveInstalledAt100: values?.dsuInactiveInstalledAt100,
          svdu5InactiveDownloadAt100: values?.svdu5InactiveDownloadAt100,
          svdu5InactiveInstalledAt100: values?.svdu5InactiveInstalledAt100,
          okToRemoveTclLoader: values?.okToRemoveTclLoader,
          activeMonth: values?.activeMonth,
          qualityCheck: values?.qualityCheck,
          depart: values?.depart,
          loadPercent: values?.loadPercent,
          notes: values?.notes,
          waitingForApproval: true,
          waitingForSubmission: false,
          approved: false,
          seatsNotWorking: values?.seatsNotWorking,
        },
      })
      .then((res) => {
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    console.log('onFieldsChange', allFields);

    let sessionSave = {
      reportId: report._id,
      station: allFields[0]?.value,
      date: allFields[1]?.value,
      tclUsed: allFields[2]?.value,
      loadTimeMins: allFields[3]?.value,
      totalTimeMins: allFields[4]?.value,
      cil: allFields[5]?.value,
      dtdOn: allFields[6]?.value,
      dtdOff: allFields[7]?.value,
      dsu1: allFields[8]?.value,
      dsu2: allFields[9]?.value,
      arrive24h: allFields[10]?.value,
      powerTCL: allFields[11]?.value,
      systemReady: allFields[12]?.value,
      downloadStart: allFields[13]?.value,
      activeDsuDownloaded: allFields[14]?.value,
      activeDsuInstalled: allFields[15]?.value,
      activeSvdu5Downloaded: allFields[16]?.value,
      activeSvdu5Installed: allFields[17]?.value,
      inactiveDsuDownloaded: allFields[18]?.value,
      inactiveDsuInstalled: allFields[19]?.value,
      inactiveSvdu5Downloaded: allFields[20]?.value,
      inactiveSvdu5Installed: allFields[21]?.value,
      dsuInactiveDownloadAt100: allFields[22]?.value,
      dsuInactiveInstalledAt100: allFields[23]?.value,
      svdu5InactiveDownloadAt100: allFields[24]?.value,
      svdu5InactiveInstalledAt100: allFields[25]?.value,
      okToRemoveTclLoader: allFields[26]?.value,
      activeMonth: allFields[27]?.value,
      qualityCheck: allFields[28]?.value,
      depart: allFields[29]?.value,
      loadPercent: allFields[30]?.value,
      notes: allFields[31]?.value,
      seatsNotWorking: allFields[32]?.value,
    };

    setFields(sessionSave);
    setReadyToSave(true);

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setIsSaving(true);

    autoSaveTimer.current = setTimeout(() => {
      sessionStorage.setItem('form', JSON.stringify(sessionSave));
      clearTimeout(autoSaveTimer.current);
      setIsSaving(false);
    }, SAVE_AFTER_MS);
  };

  const onSave = () => {
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          fields?.loadPercent > 0 && fields?.loadPercent < 100
            ? 'Partial Load'
            : fields?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        avantReport: {
          user: auth?.email,
          station: fields?.station,
          tclUsed: fields?.tclUsed,
          date: fields?.date,
          loadTimeMins: fields?.loadTimeMins,
          totalTimeMins: fields?.totalTimeMins,
          cil: fields?.cil,
          dtdOn: fields?.dtdOn,
          dtdOff: fields?.dtdOff,
          dsu1: fields?.dsu1,
          dsu2: fields?.dsu2,
          arrive24h: fields?.arrive24h,
          powerTCL: fields?.powerTCL,
          systemReady: fields?.systemReady,
          downloadStart: fields?.downloadStart,
          activeDsuDownloaded: fields?.activeDsuDownloaded,
          activeDsuInstalled: fields?.activeDsuInstalled,
          activeSvdu5Downloaded: fields?.activeSvdu5Downloaded,
          activeSvdu5Installed: fields?.activeSvdu5Installed,
          inactiveDsuDownloaded: fields?.inactiveDsuDownloaded,
          inactiveDsuInstalled: fields?.inactiveDsuInstalled,
          inactiveSvdu5Downloaded: fields?.inactiveSvdu5Downloaded,
          inactiveSvdu5Installed: fields?.inactiveSvdu5Installed,
          dsuInactiveDownloadAt100: fields?.dsuInactiveDownloadAt100,
          dsuInactiveInstalledAt100: fields?.dsuInactiveInstalledAt100,
          svdu5InactiveDownloadAt100: fields?.svdu5InactiveDownloadAt100,
          svdu5InactiveInstalledAt100: fields?.svdu5InactiveInstalledAt100,
          okToRemoveTclLoader: fields?.okToRemoveTclLoader,
          activeMonth: fields?.activeMonth,
          qualityCheck: fields?.qualityCheck,
          depart: fields?.depart,
          loadPercent: fields?.loadPercent,
          notes: fields?.notes,
          waitingForSubmission: true,
          waitingForApproval: false,
          approved: false,
          seatsNotWorking: fields?.seatsNotWorking,
        },
      })
      .then((res) => {
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <Card title={`Avant Loading Details - Edit`}>
      <Form
        form={form}
        name="avantForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Station" name="station">
              <Select placeholder="Select a station" allowClear>
                <Option value="YUL">YUL</Option>
                <Option value="YVR">YVR</Option>
                <Option value="YYC">YYC</Option>
                <Option value="YYZ">YYZ</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Date" name="date">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={17}>
            <Form.Item label="TCL used" name="tclUsed">
              <Select placeholder="Select a option" allowClear>
                <Option value="YVR1">YVR1</Option>
                <Option value="YVR2">YVR2</Option>
                <Option value="YYZ1">YYZ1</Option>
                <Option value="YYZ2">YYZ2</Option>
                <Option value="YUL1">YUL1</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Load Time (Mins)" name="loadTimeMins">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Total Time (Mins)" name="totalTimeMins">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="CIL" name="cil">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="DTD S/N On" name="dtdOn">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="DTD S/N Off" name="dtdOff">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="DSU 1" name="dsu1">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="DSU 2" name="dsu2">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Arrive (24 hours)" name="arrive24h">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Power TCL" name="powerTCL">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="System Ready" name="systemReady">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Download Start" name="downloadStart">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Active DSU Downloaded" name="activeDsuDownloaded">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Active DSU Installed" name="activeDsuInstalled">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Active SVDU5 Downloaded" name="activeSvdu5Downloaded">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Active SVDU5 Installed" name="activeSvdu5Installed">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Inactive DSU Downloaded" name="inactiveDsuDownloaded">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Inactive DSU Installed" name="inactiveDsuInstalled">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Inactive SVDU5 Downloaded" name="inactiveSvdu5Downloaded">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Inactive SVDU5 Installed" name="inactiveSvdu5Installed">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="DSU Inactive: Download @ 100%" name="dsuInactiveDownloadAt100">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="DSU Inactive: Installed @ 100%" name="dsuInactiveInstalledAt100">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="SVDU5 Inactive: Download @ 100%" name="svdu5InactiveDownloadAt100">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="SVDU5 Inactive: Installed @ 100%" name="svdu5InactiveInstalledAt100">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Ok to remove TCL Loader (time 24 hrs)" name="okToRemoveTclLoader">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Active Month" name="activeMonth">
              <Select placeholder="Select a month" allowClear>
                <Option value="JAN">JAN</Option>
                <Option value="FEB">FEB</Option>
                <Option value="MAR">MAR</Option>
                <Option value="APR">APR</Option>
                <Option value="MAY">MAY</Option>
                <Option value="JUN">JUN</Option>
                <Option value="JUL">JUL</Option>
                <Option value="AUG">AUG</Option>
                <Option value="SEP">SEP</Option>
                <Option value="OCT">OCT</Option>
                <Option value="NOV">NOV</Option>
                <Option value="DEC">DEC</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Quality Check (time 24 hrs)" name="qualityCheck">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Depart (time 24 hrs) " name="depart">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Load %" name="loadPercent">
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item label="Notes" name="notes" style={{ width: '100%' }}>
            <TextArea />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item label="Seats Not Working" name="seatsNotWorking">
            <InputNumber min={0} max={100} />
          </Form.Item>
        </Row>

        <Form.Item className="submit-button">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

          <Button type="primary" onClick={onSave} disabled={!readyToSave} style={{ marginLeft: 20 }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
