import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Row, Col } from 'antd';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import AircraftTable from '../components/AircraftTable';
import AddAircraftModal from '../components/AddAircraftModal';
import CreateBatchModal from '../components/CreateBatchModal';
import { hasPermission } from '../helpers';

const { Title } = Typography;

export default function AircraftScreen() {
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [isAircraftModalVisible, setIsAircraftModalVisible] = useState(false);
  const [isBatchModalVisible, setIsBatchModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [aircraftToBatch, setAircraftToBatch] = useState([]);
  const [viewAircraftPermission, setViewAircraftPermission] = useState(false);
  const [editAircraftPermission, setEditAircraftPermission] = useState(false);

  const fetchAircraft = () => {
    axios.get('/api/aircraft', { withCredentials: true }).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetchAircraft();
  }, [isAircraftModalVisible]);

  useEffect(() => {
    if (roles && auth) {
      setViewAircraftPermission(hasPermission('viewAircraft', auth.role, roles));
      setEditAircraftPermission(hasPermission('editAircraft', auth.role, roles));
    }
  }, [roles, auth]);

  const showAircraftModal = () => {
    setIsAircraftModalVisible(true);
  };

  const handleAircraftOk = () => {
    setIsAircraftModalVisible(false);
  };

  const handleAircraftCancel = () => {
    setIsAircraftModalVisible(false);
  };

  const showBatchModal = () => {
    setIsBatchModalVisible(true);
  };

  const handleBatchOk = () => {
    setIsBatchModalVisible(false);
  };

  const handleBatchCancel = () => {
    setIsBatchModalVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setAircraftToBatch(checkedValues);
  };

  return (
    <MainLayout>
      {viewAircraftPermission ? (
        <>
          <AddAircraftModal visible={isAircraftModalVisible} onOk={handleAircraftOk} onCancel={handleAircraftCancel} />
          <CreateBatchModal
            visible={isBatchModalVisible}
            onOk={handleBatchOk}
            onCancel={handleBatchCancel}
            aircraft={aircraftToBatch}
          />
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">Aircraft</Title>
            </Col>
            <Col flex="auto"></Col>
            {editAircraftPermission ? (
              <Col className="buttons">
                <Button className="header-button-1 green-btn" onClick={showBatchModal}>
                  Create Cycle
                </Button>
                <Button className="header-button-2" type="primary" onClick={showAircraftModal}>
                  Add New
                </Button>
              </Col>
            ) : null}
          </Row>
          <Row className="secondary-section">
            <Col flex="auto"></Col>
            <Col className="buttons">
              <CSVLink filename={'aircraft_export.csv'} data={data.map(({ _id, __v, ...item }) => item)}>
                <Button className="header-button-2">Export to CSV</Button>
              </CSVLink>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <AircraftTable
                fetchAircraft={fetchAircraft}
                data={data}
                handleCheck={handleCheckboxChange}
                editAircraftPermission
              />
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
