import React, { useState, useEffect } from 'react';
import { Table, Typography, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function WaitingForSubmissionTable({ batch }) {
  const navigate = useNavigate();
  const [waitingForSubmission, setWaitingForSubmission] = useState([]);

  // Filter for all reports that are waiting for submission
  useEffect(() => {
    if (batch) {
      if (batch.aircraftReports) {
        batch.aircraftReports.forEach((report) => {
          if (
            report?.i4500Report?.waitingForSubmission ||
            report?.gogoReport?.waitingForSubmission ||
            report?.avantReport?.waitingForSubmission ||
            report?.avantDtdReport?.waitingForSubmission ||
            report?.atpdrReport?.waitingForSubmission
          ) {
            setWaitingForSubmission((prev) => [...prev, report]);
          }
        });
      }
    }
  }, [batch]);

  const columns = [
    {
      title: 'Fin',
      dataIndex: 'fin',
      key: 'fin',
      render: (text, record) => {
        return (
          <>
            <a href="#/" onClick={() => navigate(`/batch/${batch._id}/report/${record._id}`)}>
              {text}
            </a>
            <br />
            <>Report #{record.numberInBatch}</>
          </>
        );
      },
    },
    {
      title: 'Media Type',
      dataIndex: 'mediaType',
      key: 'mediaType',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Percent Loaded',
      dataIndex: 'loadPercent',
      key: 'loadPercent',
      render: (text, record) => {
        switch (record?.mediaType) {
          case 'i4500':
            return record?.i4500Report?.loadPercent || 0;

          case 'GoGo':
            return record?.gogoReport?.loadPercent || 0;

          case 'Avant':
            return record?.avantReport?.loadPercent || record?.avantDtdReport?.loadPercent || 0;

          case 'ATPDR':
            return record?.atpdrReport?.loadPercent || 0;

          default:
            return '-';
        }
      },
    },
    {
      title: 'Current Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      render: (text, record) => {
        return <Tag color="purple">{text}</Tag>;
      },
    },
  ];

  return WaitingForSubmissionTable.length > 0 ? (
    <>
      <Title level={4} style={{ marginTop: '4%' }}>
        Waiting for submission
      </Title>

      <Table
        columns={columns}
        dataSource={waitingForSubmission}
        rowKey="_id"
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
      />
    </>
  ) : (
    <></>
  );
}
