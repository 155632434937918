import { Modal, Form, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const { Option } = Select;

// Allow adding and removing fins from this report
export default function AddBatchFinsModal(props) {
  const [form] = Form.useForm();
  const [aircraft, setAircraft] = useState([]);

  // Get all aircraft from the database
  useEffect(() => {
    axios
      .get('/api/aircraft')
      .then((res) => {
        setAircraft(res.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const handleAddChange = (value) => {
    console.log(`add selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // For all submitted values
  // If the fin already exists in this batch, do nothing
  // If the fin existed in the batch and is now removed, remove it from the batch,
  // If the fin is new, add it to the batch
  const handleSubmit = () => {
    let values = form.getFieldsValue(true);
    console.log('fin submit values', values);

    let addRequest = [];

    // Add new fins to the batch
    let finsToAdd = values.finsToAdd || [];

    aircraft.map((plane) => {
      if (finsToAdd.includes(plane.fin)) {
        // If the fin is already in the batch, count the number of times it appears and set the numberInBatch to that + 1

        let finCount = 0;
        props.batch.aircraftReports.map((report) => {
          if (report.fin === plane.fin) {
            finCount++;
          }
        });

        addRequest.push({
          fin: plane.fin,
          mediaType: plane.mediaType,
          model: plane.model,
          numberInBatch: finCount + 1,
        });
      }
    });

    if (addRequest.length > 0) {
      addRequest.map((obj) => {
        axios
          .post(`/api/batch/${props.batch._id}/report`, obj)
          .then((res) => {
            props.onOk();
            window.location.href = `/batch/${props.batch._id}/report/${res.data._id}`;
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
    }
  };

  return (
    <Modal title="Add a Report" visible={props.visible} onOk={handleSubmit} onCancel={props.onCancel} width={'66%'}>
      <Form form={form} layout="vertical" name="addAircraft" autoComplete="off">
        <Form.Item label="Fins to Add" name="finsToAdd">
          <Select
            showSearch
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Add fins to report"
            optionFilterProp="children"
            onChange={handleAddChange}
            filterOption={filterOption}
            onSearch={onSearch}
            options={
              aircraft &&
              aircraft.map((aircraft) => ({
                label: `Fin: ${aircraft.fin}`,
                value: aircraft.fin,
              }))
            }
          >
            {/* {aircraft.map((aircraft) => (
              <Option key={aircraft._id} value={aircraft.fin}>
                Fin: {aircraft.fin}
              </Option>
            ))} */}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
