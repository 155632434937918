import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../App.css';

import LoginScreen from '../screens/LoginScreen';
import HomeScreen from '../screens/HomeScreen';
import AircraftScreen from '../screens/AircraftScreen';
import BatchesScreen from '../screens/BatchesScreen';
import SingleBatchScreen from '../screens/SingleBatchScreen';
import SingleReportScreen from '../screens/SingleReportScreen';
import ShippingScreen from '../screens/ShippingScreen';
import UsersScreen from '../screens/UsersScreen';
import ProfileScreen from '../screens/ProfileScreen';

class App extends Component {
  componentDidMount() {
    this.props.fetchUser(); // fetches the current user
    this.props.fetchRoles(); // fetches all the roles when the app first loads
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/aircraft" element={<AircraftScreen />} />
          <Route exact path="/loading" element={<BatchesScreen />} />
          <Route exact path="/shipping" element={<ShippingScreen />} />
          <Route exact path="/users" element={<UsersScreen />} />
          <Route exact path="/batch/:id" element={<SingleBatchScreen />} />
          <Route exact path="/batch/:batchId/report/:reportId" element={<SingleReportScreen />} />
          <Route exact path="/profile" element={<ProfileScreen />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);
