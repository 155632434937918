import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Select, Button } from 'antd';
import DatePicker from '../DatePicker';
import axios from 'axios';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const { Option } = Select;
const { TextArea } = Input;

const SAVE_AFTER_MS = 1000;

export default function AtpdrEditCard({ report, batchId }) {
  const auth = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const autoSaveTimer = useRef();

  const [readyToSave, setReadyToSave] = useState(false);
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (sessionStorage?.form) {
      if (JSON.parse(sessionStorage?.form)?.reportId === report._id) {
        let sessionFields = JSON.parse(sessionStorage.form);
        console.log('sessionFields', sessionFields);

        form.setFieldsValue({
          station: sessionFields?.station || '',
          flightNumber: sessionFields?.flightNumber || '',
          disability: sessionFields?.disability || '',
          caseNumberOn: sessionFields?.caseNumberOn || '',
          caseNumberOff: sessionFields?.caseNumberOff || '',
          month: sessionFields?.month || '',
          loadPercent: sessionFields?.loadPercent || '',
          notes: sessionFields?.notes || '',
        });

        if (sessionFields?.date) {
          form.setFieldsValue({
            date: dayjs(sessionFields?.date) || '',
          });
        }
      }
    } else {
      form.setFieldsValue({
        station: report?.atpdrReport?.station || '',
        flightNumber: report?.atpdrReport?.flightNumber || '',
        disability: report?.atpdrReport?.disability || '',
        caseNumberOn: report?.atpdrReport?.caseNumberOn || '',
        caseNumberOff: report?.atpdrReport?.caseNumberOff || '',
        month: report?.atpdrReport?.month || '',
        loadPercent: report?.atpdrReport?.loadPercent || '',
        notes: report?.atpdrReport?.notes || '',
      });

      if (report?.atpdrReport?.date) {
        form.setFieldsValue({
          date: dayjs(report?.atpdrReport?.date) || '',
        });
      }
    }
  }, [report]);

  // Submit values to database
  const onFinish = (values) => {
    console.log('Success:', values);
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          values?.loadPercent > 0 && values?.loadPercent < 100
            ? 'Partial Load'
            : values?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        atpdrReport: {
          user: auth?.email,
          station: values?.station,
          flightNumber: values?.flightNumber,
          disability: values?.disability,
          date: values?.date,
          fin: report?.fin,
          caseNumberOn: values?.caseNumberOn,
          caseNumberOff: values?.caseNumberOff,
          month: values?.month,
          loadPercent: values?.loadPercent,
          notes: values?.notes,
          waitingForApproval: true,
          waitingForSubmission: false,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    console.log('onFieldsChange', changedFields, allFields);

    let sessionSave = {
      reportId: report._id,
      station: allFields[0]?.value,
      flightNumber: allFields[1]?.value,
      disability: allFields[2]?.value,
      date: allFields[3]?.value,
      caseNumberOn: allFields[4]?.value,
      caseNumberOff: allFields[5]?.value,
      month: allFields[6]?.value,
      notes: allFields[7]?.value,
    };

    setFields(sessionSave);
    setReadyToSave(true);

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setIsSaving(true);

    autoSaveTimer.current = setTimeout(() => {
      sessionStorage.setItem('form', JSON.stringify(sessionSave));
      clearTimeout(autoSaveTimer.current);
      setIsSaving(false);
    }, SAVE_AFTER_MS);
  };

  const onSave = () => {
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          fields?.loadPercent > 0 && fields?.loadPercent < 100
            ? 'Partial Load'
            : fields?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        atpdrReport: {
          user: auth?.email,
          station: fields?.station,
          flightNumber: fields?.flightNumber,
          disability: fields?.disability,
          date: fields?.date,
          fin: report?.fin,
          caseNumberOn: fields?.caseNumberOn,
          caseNumberOff: fields?.caseNumberOff,
          month: fields?.month,
          loadPercent: fields?.loadPercent,
          notes: fields?.notes,
          waitingForSubmission: true,
          waitingForApproval: false,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <Card title={`ATPDR Loading Details - Edit`}>
      <Form
        form={form}
        name="atpdrForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Station" name="station">
              <Select placeholder="Select a station" allowClear>
                <Option value="YUL">YUL</Option>
                <Option value="YVR">YVR</Option>
                <Option value="YYC">YYC</Option>
                <Option value="YYZ">YYZ</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Flight Number" name="flightNumber">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Disability" name="disability">
              <Select placeholder="Select a disability" allowClear>
                <Option value="Blind">Blind</Option>
                <Option value="Deaf">Deaf</Option>
                <Option value="Blind & Deaf">Blind & Deaf</Option>
                <Option value="Blind & Deaf">Monthly Update</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Date" name="date">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Case Number On" name="caseNumberOn">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Case Number Off" name="caseNumberOff">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Month" name="month">
              <Select placeholder="Select a month" allowClear>
                <Option value="JAN">JAN</Option>
                <Option value="FEB">FEB</Option>
                <Option value="MAR">MAR</Option>
                <Option value="APR">APR</Option>
                <Option value="MAY">MAY</Option>
                <Option value="JUN">JUN</Option>
                <Option value="JUL">JUL</Option>
                <Option value="AUG">AUG</Option>
                <Option value="SEP">SEP</Option>
                <Option value="OCT">OCT</Option>
                <Option value="NOV">NOV</Option>
                <Option value="DEC">DEC</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col flex="auto"></Col> */}
          {/* <Col xl={12} xs={24}>
            <Form.Item
              label="Load %"
              name="loadPercent"
              rules={[
                {
                  required: true,
                  message: 'Required field',
                },
              ]}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col> */}
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Notes" name="notes">
              <TextArea />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
        </Row>
        <Form.Item className="submit-button">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

          <Button type="primary" onClick={onSave} disabled={!readyToSave} style={{ marginLeft: 20 }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
