import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import ContentLoadingCard from '../components/ContentLoadingCard';
import { hasPermission } from '../helpers';
import { formatDate } from '../utils/formatDate';

export default function HomeScreen() {
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [recentBatch, setRecentBatch] = useState({});
  const [viewDashboardPermission, setViewDashboardPermission] = useState(false);

  useEffect(() => {
    if (roles && auth) {
      setViewDashboardPermission(hasPermission('viewDashboard', auth.role, roles));
    }
  }, [roles, auth]);

  // Find the most recent batch (by active month)
  useEffect(() => {
    let mostRecentBatch = {};

    axios
      .get('/api/batch')
      .then((res) => {
        let batches = res.data;

        batches.forEach((batch) => {
          if (!mostRecentBatch._id || dayjs(mostRecentBatch.activeMonth).isBefore(batch.activeMonth)) {
            mostRecentBatch = batch;
          }
        });

        setRecentBatch(mostRecentBatch);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MainLayout>
      {viewDashboardPermission ? (
        <>
          <Row className="secondary-section" style={{ marginBottom: '20px' }}>
            <Col flex="auto"></Col>
            {recentBatch?.aircraftReports?.length ? (
              <CSVLink
                filename={`Active Month: ${dayjs(recentBatch?.activeMonth).format('MMM YYYY')}_export.csv`}
                data={recentBatch?.aircraftReports?.map((report) => {
                  return {
                    fin: report?.fin,
                    mediaType: report?.mediaType,
                    model: report?.model,
                    currentStatus: report?.currentStatus,
                    i4500user: report?.i4500Report?.user,
                    i4500station: report?.i4500Report?.station,
                    i4500date: report?.i4500Report?.date && formatDate(report?.i4500Report?.date),
                    i4500loadStartingAt:
                      report?.i4500Report?.loadStartingAt && formatDate(report?.i4500Report?.loadStartingAt),
                    i4500loadStartedTime:
                      report?.i4500Report?.loadStartedTime &&
                      dayjs(report?.i4500Report?.loadStartedTime).format('YYYY-MM-DD HH:mm:ss'),
                    i4500timeOnAircraft: report?.i4500Report?.timeOnAircraft,
                    i4500timeOffAircraft: report?.i4500Report?.timeOffAircraft,
                    i4500loadTimeMins: report?.i4500Report?.loadTimeMins,
                    i4500totalTimeMins: report?.i4500Report?.totalTimeMins,
                    i4500currentAircraftMonthOnAC: report?.i4500Report?.currentAircraftMonthOnAC,
                    i4500percentAtStart: report?.i4500Report?.percentAtStart,
                    i4500safetyAds: report?.i4500Report?.safetyAds,
                    i4500safetyVideo: report?.i4500Report?.safetyVideo,
                    i4500xmRadio: report?.i4500Report?.xmRadio,
                    i4500hollywoodNewTv: report?.i4500Report?.hollywoodNewTv,
                    i4500prams: report?.i4500Report?.prams,
                    i4500other: report?.i4500Report?.other,
                    i4500pdl1: report?.i4500Report?.pdl1,
                    i4500pdl2: report?.i4500Report?.pdl2,
                    i4500hd1: report?.i4500Report?.hd1,
                    i4500hd2: report?.i4500Report?.hd2,
                    i4500dsu1: report?.i4500Report?.dsu1,
                    i4500dsu2: report?.i4500Report?.dsu2,
                    i4500dsu3: report?.i4500Report?.dsu3,
                    i4500dsu4: report?.i4500Report?.dsu4,
                    i4500dsu5: report?.i4500Report?.dsu5,
                    i4500dsu6: report?.i4500Report?.dsu6,
                    i4500mdataOffloadCurrent: report?.i4500Report?.mdataOffloadCurrent,
                    i4500mdataOffloadArchive: report?.i4500Report?.mdataOffloadArchive,
                    i4500vikKit: report?.i4500Report?.vikKit,
                    i4500vikKitReplaced: report?.i4500Report?.vikKitReplaced,
                    i4500powerSourceType: report?.i4500Report?.powerSourceType,
                    i4500confirmedCIL: report?.i4500Report?.confirmedCIL,
                    i4500activeMonth: report?.i4500Report?.activeMonth,
                    i4500billingMonth: report?.i4500Report?.billingMonth,
                    i4500loadPercent: report?.i4500Report?.loadPercent,
                    i4500loadingDelayCode: report?.i4500Report?.loadingDelayCode,
                    i4500loadingDelayNotes: report?.i4500Report?.loadingDelayNotes,
                    i4500notes: report?.i4500Report?.notes,
                    i4500waitingForApproval: report?.i4500Report?.waitingForApproval,
                    i4500approved: report?.i4500Report?.approved,
                    gogoUser: report?.gogoReport?.user,
                    gogoStation: report?.gogoReport?.station,
                    gogoDate: report?.gogoReport?.date && formatDate(report?.gogoReport?.date),
                    gogoSerialNumberOn: report?.gogoReport?.serialNumberOn,
                    gogoSerialNumberOff: report?.gogoReport?.serialNumberOff,
                    gogoWaitingForApproval: report?.gogoReport?.waitingForApproval,
                    gogoApproved: report?.gogoReport?.approved,
                    gogoLoadPercent: report?.gogoReport?.loadPercent,
                    gogoNotes: report?.gogoReport?.notes,
                    avantUser: report?.avantReport?.user,
                    avantStation: report?.avantReport?.station,
                    avantDate: report?.avantReport?.date && formatDate(report?.avantReport?.date),
                    avantLoadTimeMins: report?.avantReport?.loadTimeMins,
                    avantTotalTimeMins: report?.avantReport?.totalTimeMins,
                    avantCil: report?.avantReport?.cil,
                    avantDtdOn: report?.avantReport?.dtdOn,
                    avantDtdOff: report?.avantReport?.dtdOff,
                    avantDsu1: report?.avantReport?.dsu1,
                    avantDsu2: report?.avantReport?.dsu2,
                    avantArrive24h: report?.avantReport?.arrive24h,
                    avantPowerTCL: report?.avantReport?.powerTCL,
                    avantSystemReady: report?.avantReport?.systemReady,
                    avantDownloadStart: report?.avantReport?.downloadStart,
                    avantActiveDsuDownloaded: report?.avantReport?.activeDsuDownloaded,
                    avantActiveDsuInstalled: report?.avantReport?.activeDsuInstalled,
                    avantActiveSvdu5Downloaded: report?.avantReport?.activeSvdu5Downloaded,
                    avantActiveSvdu5Installed: report?.avantReport?.activeSvdu5Installed,
                    avantInactiveDsuDownloaded: report?.avantReport?.inactiveDsuDownloaded,
                    avantInactiveDsuInstalled: report?.avantReport?.inactiveDsuInstalled,
                    avantInactiveSvdu5Downloaded: report?.avantReport?.inactiveSvdu5Downloaded,
                    avantInactiveSvdu5Installed: report?.avantReport?.inactiveSvdu5Installed,
                    avantDsuInactiveDownloadAt100: report?.avantReport?.dsuInactiveDownloadAt100,
                    avantDsuInactiveInstalledAt100: report?.avantReport?.dsuInactiveInstalledAt100,
                    avantSvdu5InactiveDownloadAt100: report?.avantReport?.svdu5InactiveDownloadAt100,
                    avantSvdu5InactiveInstalledAt100: report?.avantReport?.svdu5InactiveInstalledAt100,
                    avantOkToRemoveTclLoader: report?.avantReport?.okToRemoveTclLoader,
                    avantActiveMonth: report?.avantReport?.activeMonth,
                    avantQualityCheck: report?.avantReport?.qualityCheck,
                    avantDepart: report?.avantReport?.depart,
                    avantLoadPercent: report?.avantReport?.loadPercent,
                    avantNotes: report?.avantReport?.notes,
                    avantWaitingForApproval: report?.avantReport?.waitingForApproval,
                    avantApproved: report?.avantReport?.approved,
                    avantDtdUser: report?.avantDtdReport?.user,
                    avantDtdStation: report?.avantDtdReport?.station,
                    avantDtdDate: report?.avantDtdReport?.date && formatDate(report?.avantDtdReport?.date),
                    avantDtdDtdSerialOn: report?.avantDtdReport?.dtdSerialOn,
                    avantDtdDtdSerialOff: report?.avantDtdReport?.dtdSerialOff,
                    avantDtdDtdMonth: report?.avantDtdReport?.dtdMonth,
                    avantDtdLoadPercent: report?.avantDtdReport?.loadPercent,
                    avantDtdNotes: report?.avantDtdReport?.notes,
                    avantDtdWaitingForApproval: report?.avantDtdReport?.waitingForApproval,
                    avantDtdApproved: report?.avantDtdReport?.approved,
                    atpdrUser: report?.atpdrReport?.user,
                    atpdrStation: report?.atpdrReport?.station,
                    atpdrFlightNumber: report?.atpdrReport?.flightNumber,
                    atpdrDisability: report?.atpdrReport?.disability,
                    atpdrDate: report?.atpdrReport?.date && formatDate(report?.atpdrReport?.date),
                    atpdrCaseNumberOn: report?.atpdrReport?.caseNumberOn,
                    atpdrCaseNumberOff: report?.atpdrReport?.caseNumberOff,
                    atpdrMonth: report?.atpdrReport?.month,
                    atpdrLoadPercent: report?.atpdrReport?.loadPercent,
                    atpdrNotes: report?.atpdrReport?.notes,
                    atpdrWaitingForApproval: report?.atpdrReport?.waitingForApproval,
                    atpdrApproved: report?.atpdrReport?.approved,
                  };
                })}
              >
                <Button className="header-button-2">Export to CSV</Button>
              </CSVLink>
            ) : null}
            <Col className="buttons"></Col>
          </Row>
          <ContentLoadingCard batch={recentBatch} title="Content Loading - Latest Report" />
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
