import React from 'react';
import { Table, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function BatchesTable(props) {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'batchName',
      key: 'batchName',
      render: (text, record) => {
        if (record?._id) {
          return (
            <a href="#/" onClick={() => navigate(`/batch/${record._id}`)}>
              {text}
            </a>
          );
        }
      },
    },
    {
      title: 'Month to Load',
      dataIndex: 'monthToLoad',
      key: 'monthToLoad',
      sorter: (a, b) => {
        return dayjs(a.monthToLoad).diff(dayjs(b.monthToLoad));
      },
      render: (text, record) => {
        return <>{dayjs(text).format('MMM YYYY')}</>;
      },
    },
    {
      title: 'Billing Month',
      dataIndex: 'billingMonth',
      key: 'billingMonth',
      sorter: (a, b) => {
        return dayjs(a.billingMonth).diff(dayjs(b.billingMonth));
      },
      render: (text, record) => {
        return <>{dayjs(text).format('MMM YYYY')}</>;
      },
    },
    {
      title: 'Active Month',
      dataIndex: 'activeMonth',
      key: 'activeMonth',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        return dayjs(a.activeMonth).diff(dayjs(b.activeMonth));
      },
      render: (text, record) => {
        return <>{dayjs(text).format('MMM YYYY')}</>;
      },
    },
    {
      title: 'Load Progress',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        // Find the number of aircraft with 100% load progress
        if (record?.aircraftReports) {
          let loaded = record.aircraftReports.filter((report) => {
            return report?.currentStatus === 'Fully Loaded';
          });

          return (
            <>
              {loaded.length}/{record.aircraftReports.length}
            </>
          );
        } else return '-';
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
    },
  ];

  return props.data !== [] ? (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey="_id"
      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
    />
  ) : (
    <Spin />
  );
}
