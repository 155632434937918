import React from 'react';
import { Card } from 'antd';
import { formatDate } from '../../utils/formatDate';

export default function GoGoDisplayCard({ report }) {
  return (
    <Card title={`Gogo Loading Details`}>
      <div>
        <b>User</b>
        <p>{report?.gogoReport?.user || '-'}</p>
      </div>
      <div>
        <b>Station</b>
        <p>{report?.gogoReport?.station || '-'}</p>
      </div>
      <div>
        <b>Date</b>
        <p>{report?.gogoReport?.date ? report?.gogoReport?.date.replace('T', ' ').split('.')[0] + ' GMT' : '-'}</p>
      </div>
      <div>
        <b>Fin</b>
        <p>{report?.gogoReport?.fin || '-'}</p>
      </div>
      <div>
        <b>Serial Number On</b>
        <p>{report?.gogoReport?.serialNumberOn || '-'}</p>
      </div>
      <div>
        <b>Serial Number Off</b>
        <p>{report?.gogoReport?.serialNumberOff || '-'}</p>
      </div>
      <div>
        <b>Batch #</b>
        <p>{report?.gogoReport?.batch || '-'}</p>
      </div>
      {/* <div>
        <b>Load Percent</b>
        <p>{report?.gogoReport?.loadPercent || '-'}</p>
      </div> */}
      <div>
        <b>Notes</b>
        <p>{report?.gogoReport?.notes || '-'}</p>
      </div>
    </Card>
  );
}
