import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Spin, Modal, Button, Row } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddUserModal from './AddUserModal';
import RemoveUserModal from './RemoveUserModal';

export default function UsersTable(props) {
  const [isRemoveUserModalVisible, setIsRemoveUserModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);

  const [userSelected, setUserSelected] = useState(null);
  const navigate = useNavigate();

  const showUserModal = () => {
    setIsUserModalVisible(true);
  };

  const handleUserOk = () => {
    setIsUserModalVisible(false);
  };

  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleRemoveUserModal = () => setIsRemoveUserModalVisible(!isRemoveUserModalVisible);

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => {
        switch (text) {
          case 3:
            return 'Admin';
          case 2:
            return 'Manager';
          case 1:
            return 'Client';
          case 0:
            return 'User';
          default:
            return 'Unknown';
        }
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Row>
          <Button
            type="link"
            onClick={() => {
              showUserModal();
              setUserSelected(record);
            }}
          >
            Edit
          </Button>

          <Button
            type="link"
            onClick={() => {
              handleRemoveUserModal();
              setUserSelected(record);
            }}
            style={{ color: 'red' }}
          >
            Delete
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.data}
        rowKey="_id"
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
      />

      <AddUserModal
        visible={isUserModalVisible}
        onOk={handleUserOk}
        onCancel={handleUserCancel}
        fetchUserData={props.fetchUserData}
        userSelected={userSelected}
      />

      <RemoveUserModal
        visible={isRemoveUserModalVisible}
        onCancel={handleRemoveUserModal}
        fetchUserData={props.fetchUserData}
        userSelected={userSelected}
      />
    </>
  );
}
