import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import BatchesTable from '../components/BatchesTable';
import { hasPermission } from '../helpers';
import dayjs from 'dayjs';

const { Title } = Typography;

export default function BatchesScreen() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [viewBatchesPermission, setViewBatchesPermission] = useState(false);
  const [batches, setBatches] = useState([]);
  const [mostRecentBatch, setMostRecentBatch] = useState(null);
  const [oldBatches, setOldBatches] = useState(null);

  // console.log('mostRecentBatch', mostRecentBatch);
  console.log('batches', batches);

  useEffect(() => {
    let mostRecent = null;

    axios.get('/api/batch', { withCredentials: true }).then((res) => {
      setBatches(res.data);

      // Get the most recent batch
      res.data.forEach((batch) => {
        if (!mostRecent) {
          mostRecent = batch;
        }
        if (dayjs(batch?.activeMonth).isAfter(dayjs(mostRecent?.activeMonth))) {
          mostRecent = batch;
        }
      });

      setMostRecentBatch(mostRecent);
    });
  }, []);

  // If there's a most recent batch, find the remaining batches and set them to oldBatches
  useEffect(() => {
    if (batches && mostRecentBatch) {
      const filteredOldBatches = batches.filter((batch) => batch._id !== mostRecentBatch._id);
      console.log('filteredOldBatches', filteredOldBatches);
      setOldBatches(filteredOldBatches);
    }
  }, [batches, mostRecentBatch]);

  useEffect(() => {
    if (roles && auth) {
      setViewBatchesPermission(hasPermission('viewBatches', auth.role, roles));
    }
  }, [roles, auth]);

  return (
    <MainLayout>
      {viewBatchesPermission ? (
        <>
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">Cycles</Title>
            </Col>
            <Col flex="auto"></Col>
            <Col className="buttons">
              <Button onClick={() => navigate('/aircraft')}>Add New</Button>
            </Col>
          </Row>
          <Row className="secondary-section">
            <Col flex="auto"></Col>
            <Col className="buttons">
              <CSVLink
                filename={'batches_export.csv'}
                data={batches.map((batch) => {
                  return {
                    batchName: batch.batchName,
                    monthToLoad: dayjs(batch.monthToLoad).format('MMM YYYY'),
                    billingMonth: dayjs(batch.billingMonth).format('MMM YYYY'),
                    activeMonth: dayjs(batch.activeMonth).format('MMM YYYY'),
                    aircraftReports: batch.aircraftReports.length,
                  };
                })}
              >
                <Button className="header-button-2">Export to CSV</Button>
              </CSVLink>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              {/* If role is User (role ID 0), only show the most recent batch */}
              {auth.role === 0 && mostRecentBatch ? (
                <BatchesTable data={[mostRecentBatch]} />
              ) : auth.role === 0 && !mostRecentBatch ? (
                <></>
              ) : mostRecentBatch ? (
                <>
                  <BatchesTable data={[mostRecentBatch]} />

                  <Title level={3}>Old Cycles</Title>
                  {oldBatches && oldBatches.length > 0 ? (
                    <BatchesTable data={oldBatches} />
                  ) : (
                    <BatchesTable data={[]} />
                  )}
                </>
              ) : null}
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
