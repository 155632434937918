import { Modal, Form, Input, Select, Row, Col } from 'antd';
import React from 'react';
import axios from 'axios';
import DatePicker from './DatePicker';

const { Option } = Select;

export default function AddShippingModal(props) {
  const [form] = Form.useForm();

  const handleSelectChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleSubmit = () => {
    let values = form.getFieldsValue(true);
    const {
      station,
      date,
      description,
      item,
      contentMonth,
      waybillIncoming,
      waybillOutgoing,
      shipTo,
      recievedFrom,
      notes,
    } = values;

    axios
      .post('/api/shipping', {
        station,
        date,
        description,
        item,
        contentMonth,
        waybillIncoming,
        waybillOutgoing,
        shipTo,
        recievedFrom,
        notes,
      })
      .then((res) => {
        props.onOk();
      });
  };

  return (
    <Modal title="Add Aircraft" visible={props.visible} onOk={handleSubmit} onCancel={props.onCancel} width={'66%'}>
      <Form form={form} layout="vertical" name="addAircraft" autoComplete="off">
        <Row>
          <Col xl={12} xs={24}>
            <Form.Item label="Station" name="station" rules={[{ required: true, message: 'Please add a station' }]}>
              <Select style={{ width: 120 }} onChange={handleSelectChange}>
                <Option value="YUL">YUL</Option>
                <Option value="YVR">YVR</Option>
                <Option value="YYC">YYC</Option>
                <Option value="YYZ">YYZ</Option>
                <Option value="RON">RON</Option>
                <Option value="WAREHOUSE">WAREHOUSE</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: 'Please input a date',
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xl={12} xs={24}>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Serial, PL, PDL, Cyber, HD Drive, Part, OTHER NUMBER " name="item">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Content Month" name="contentMonth">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Waybill # Incoming" name="waybillIncoming">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Waybill # Outgoing" name="waybillOutgoing">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ship To" name="shipTo">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Received From" name="recievedFrom">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Notes" name="notes">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
