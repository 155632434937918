import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import logo from '../assets/ac_ops_portal_logo.png';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { hasPermission } from '../helpers';

const { Sider } = Layout;

export default function LayoutSider() {
  const { height, width } = useWindowDimensions();
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [collapsed, setCollapsed] = React.useState(width > 768 ? false : true);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [viewDashboardPermission, setViewDashboardPermission] = useState(false);
  const [viewBatchesPermission, setViewBatchesPermission] = useState(false);
  const [viewAircraftPermission, setViewAircraftPermission] = useState(false);
  const [viewShippingPermission, setViewShippingPermission] = useState(false);
  const [addUsersPermission, setAddUsersPermission] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    if (roles && auth) {
      setViewDashboardPermission(hasPermission('viewDashboard', auth.role, roles));
      setViewBatchesPermission(hasPermission('viewBatches', auth.role, roles));
      setViewAircraftPermission(hasPermission('viewAircraft', auth.role, roles));
      setViewShippingPermission(hasPermission('viewShipping', auth.role, roles));
      setAddUsersPermission(hasPermission('addUsers', auth.role, roles));
    }
  }, [roles, auth]);

  return (
    <Sider
      width={300}
      className="site-layout-background"
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      onCollapse={onCollapse}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <img src={logo} alt="logo" className="logo" />
        {viewDashboardPermission ? (
          <Menu.Item key="/">
            <a
              href="#/"
              onClick={() => {
                navigate('/');
              }}
            >
              Dashboard
            </a>
          </Menu.Item>
        ) : null}

        {viewBatchesPermission ? (
          <Menu.Item key="/loading">
            <a
              href="#/"
              onClick={() => {
                navigate('/loading');
              }}
            >
              Content Loading
            </a>
          </Menu.Item>
        ) : null}

        {viewAircraftPermission ? (
          <Menu.Item key="/aircraft">
            <a
              href="#/"
              onClick={() => {
                navigate('/aircraft');
              }}
            >
              Aircraft
            </a>
          </Menu.Item>
        ) : null}

        {viewShippingPermission ? (
          <Menu.Item key="/shipping">
            <a
              href="#/"
              onClick={() => {
                navigate('/shipping');
              }}
            >
              Media Shipping
            </a>
          </Menu.Item>
        ) : null}

        {addUsersPermission ? (
          <Menu.Item key="/users">
            <a
              href="#/"
              onClick={() => {
                navigate('/users');
              }}
            >
              Users
            </a>
          </Menu.Item>
        ) : null}

        <Menu.Item key="/profile">
          <a
            href="#/"
            onClick={() => {
              navigate('/profile');
            }}
          >
            Profile
          </a>
        </Menu.Item>

        <Menu.Item key="/logout">
          <a
            href="#/"
            onClick={() => {
              axios.get('/auth/logout').then(() => {
                window.location.href = '/';
              });
            }}
          >
            Log Out
          </a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
