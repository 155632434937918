import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin, Layout, Row, Col } from 'antd';
import LayoutSider from '../../components/LayoutSider';
import dayjs from 'dayjs';

const { Content, Footer } = Layout;

// Used as the main layout style for most app pages. Includes header, general content area, and footer.
export default function MainLayout(props) {
  const auth = useSelector((state) => state.auth);

  const renderContent = () => {
    switch (auth) {
      // Show spinner while user is being determined
      case null:
        return <Spin />;

      // If empty user, redirect to login
      case '': {
        window.location.href = `/login`;
        return null;
      }

      // If user exists, display layout with current page
      default:
        return (
          <Layout style={{ minHeight: '100vh' }}>
            <LayoutSider />
            <Layout className="site-layout">
              {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
              <Content style={{ padding: '2% 6%', backgroundColor: '#fafafa' }}>
                <div className="site-layout-content">{props.children}</div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>©{dayjs().format('YYYY')} Bullet Digital</Footer>
            </Layout>
          </Layout>
        );
    }
  };

  return <>{renderContent()}</>;
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
