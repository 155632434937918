import React from 'react';
import { Card } from 'antd';
import { formatDate } from '../../utils/formatDate';

export default function AvantDtdDisplayCard({ report }) {
  return (
    <Card title={`Avant DTD Loading Details`}>
      <div>
        <b>User</b>
        <p>{report?.avantDtdReport?.user || '-'}</p>
      </div>
      <div>
        <b>Station</b>
        <p>{report?.avantDtdReport?.station || '-'}</p>
      </div>
      <div>
        <b>Date</b>
        <p>
          {report?.avantDtdReport?.date ? report?.avantDtdReport?.date.replace('T', ' ').split('.')[0] + ' GMT' : '-'}
        </p>
      </div>
      <div>
        <b>DTD Serial On</b>
        <p>{report?.avantDtdReport?.dtdSerialOn || '-'}</p>
      </div>
      <div>
        <b>DTD Serial Off</b>
        <p>{report?.avantDtdReport?.dtdSerialOff || '-'}</p>
      </div>
      <div>
        <b>DTD Month</b>
        <p>{report?.avantDtdReport?.dtdMonth || '-'}</p>
      </div>
      <div>
        <b>Notes</b>
        <p>{report?.avantDtdReport?.notes || '-'}</p>
      </div>
    </Card>
  );
}
