import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Typography, Button, Modal } from 'antd';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import MainLayout from './layouts/MainLayout';
import ContentLoadingCard from '../components/ContentLoadingCard';
import BatchReportsTable from '../components/BatchReportsTable';
import ApprovalsPendingTable from '../components/ApprovalsPendingTable';
import EditBatchFinsModal from '../components/EditBatchFinsModal';
import AddMediaReportModal from '../components/AddMediaReportModal';
import AddBatchFinsModal from '../components/AddBatchFinsModal';
import { hasPermission } from '../helpers';
import dayjs from 'dayjs';
import WaitingForSubmissionTable from '../components/WaitingForSubmissionTable';
import { formatDate } from '../utils/formatDate';

const { Title } = Typography;

export default function SingleBatchScreen() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.roles);
  const [batch, setBatch] = useState({});
  const [isAddFinsModalVisible, setAddFinsModalVisible] = useState(false);
  const [isFinsModalVisible, setFinsModalVisible] = useState(false);
  const [isMediaModalVisible, setMediaModalVisible] = useState(false);
  const [viewBatchesPermission, setViewBatchesPermission] = useState(false);
  const [editBatchesPermission, setEditBatchesPermission] = useState(false);
  const [i4500ExportData, setI4500ExportData] = useState([]);
  const [gogoExportData, setGogoExportData] = useState([]);
  const [avantExportData, setAvantExportData] = useState([]);
  const [ATPDRExportData, setATPDRExportData] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    axios.get(`/api/batch/${id}`, { withCredentials: true }).then((res) => {
      setBatch(res.data);
    });
  }, [id]);

  useEffect(() => {
    if (roles && auth) {
      setViewBatchesPermission(hasPermission('viewBatches', auth.role, roles));
      setEditBatchesPermission(hasPermission('editBatches', auth.role, roles));
    }
  }, [roles, auth]);

  // If there are aircraft reports in the batch, create an array of objects for each report type to be exported to CSV
  useEffect(() => {
    if (batch?.aircraftReports?.length > 0) {
      const i4500Reports = batch.aircraftReports.filter((report) => report.mediaType === 'i4500');
      const gogoReports = batch.aircraftReports.filter((report) => report.mediaType === 'GoGo');
      const avantReports = batch.aircraftReports.filter(
        (report) => report.mediaType === 'Avant' || report.mediaType === 'Avant DTD'
      );
      const ATPDRReports = batch.aircraftReports.filter((report) => report.mediaType === 'ATPDR');

      setI4500ExportData(i4500Reports);
      setGogoExportData(gogoReports);
      setAvantExportData(avantReports);
      setATPDRExportData(ATPDRReports);
    }
  }, [batch]);

  const showAddFinsModal = () => {
    setAddFinsModalVisible(true);
  };

  const showFinsModal = () => {
    setFinsModalVisible(true);
  };

  const handleFinsOk = () => {
    setFinsModalVisible(false);
  };

  const handleFinsCancel = () => {
    setFinsModalVisible(false);
  };

  const showMediaModal = () => {
    setMediaModalVisible(true);
  };

  const handleMediaOk = () => {
    setMediaModalVisible(false);
  };

  const handleMediaCancel = () => {
    setMediaModalVisible(false);
  };

  const confirmDeleteBatch = () => {
    Modal.confirm({
      title: 'Delete Cycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this cycle? This action cannot be undone.',
      okText: 'Delete Cycle',
      cancelText: 'Cancel',
      onOk: deleteBatch,
      okType: 'danger',
    });
  };

  const deleteBatch = () => {
    axios
      .delete(`/api/batch/${id}`)
      .then((res) => {
        navigate('/loading');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <AddBatchFinsModal
        batch={batch}
        visible={isAddFinsModalVisible}
        onOk={() => {
          setAddFinsModalVisible(false);
        }}
        onCancel={() => {
          setAddFinsModalVisible(false);
        }}
      />
      {viewBatchesPermission ? (
        <>
          <EditBatchFinsModal
            batch={batch}
            visible={isFinsModalVisible}
            onOk={handleFinsOk}
            onCancel={handleFinsCancel}
          />
          <AddMediaReportModal
            batch={batch}
            visible={isMediaModalVisible}
            onOk={handleMediaOk}
            onCancel={handleMediaCancel}
          />
          <Row className="title-section" justify="space-between">
            <Col>
              <Title className="title">
                {' '}
                <ArrowLeftOutlined
                  onClick={() => {
                    navigate(`/loading`);
                  }}
                />
                {'   '} {batch?.batchName}
              </Title>
            </Col>
            <Col flex="auto"></Col>
            <Col className="buttons">
              {editBatchesPermission ? (
                <>
                  <Button danger className="header-button-1" onClick={confirmDeleteBatch}>
                    Delete Cycle
                  </Button>
                  <Button className="header-button-1" onClick={showFinsModal}>
                    Add/Remove Fins
                  </Button>
                  <Button className="header-button-1" onClick={showMediaModal}>
                    Add Report by Media Type
                  </Button>
                </>
              ) : null}
              <Button type="primary" className="header-button-1" onClick={showAddFinsModal}>
                Add New Report
              </Button>
            </Col>
          </Row>
          <Row className="secondary-section">
            <Col flex="auto"></Col>
            <Col className="buttons">
              {batch?.aircraftReports?.length ? (
                <>
                  <CSVLink
                    filename={`${batch?.batchName}_i4500_export.csv`}
                    data={i4500ExportData?.map((report) => {
                      return {
                        fin: report?.fin,
                        mediaType: report?.mediaType,
                        model: report?.model,
                        currentStatus: report?.currentStatus,
                        i4500station: report?.i4500Report?.station,
                        i4500date: report?.i4500Report?.date && formatDate(report?.i4500Report?.date),
                        /* i4500loadStartingAt:
                          report?.i4500Report?.loadStartingAt && formatDate(report?.i4500Report?.loadStartingAt),
                        i4500loadStartedTime:
                          report?.i4500Report?.loadStartedTime &&
                          dayjs(report?.i4500Report?.loadStartedTime).format('YYYY-MM-DD HH:mm:ss'),
                        i4500timeOnAircraft: report?.i4500Report?.timeOnAircraft, */
                        i4500loadTimeMins: report?.i4500Report?.loadTimeMins,
                        i4500totalTimeMins: report?.i4500Report?.totalTimeMins,
                        i4500percentAtStart: report?.i4500Report?.percentAtStart,
                        /*i4500safetyAds: report?.i4500Report?.safetyAds,
                        i4500safetyVideo: report?.i4500Report?.safetyVideo,
                        i4500xmRadio: report?.i4500Report?.xmRadio,
                        i4500hollywoodNewTv: report?.i4500Report?.hollywoodNewTv,
                        i4500prams: report?.i4500Report?.prams,
                        i4500other: report?.i4500Report?.other,
                        i4500pdl1: report?.i4500Report?.pdl1,
                        i4500pdl2: report?.i4500Report?.pdl2,
                        i4500hd1: report?.i4500Report?.hd1,
                        i4500hd2: report?.i4500Report?.hd2, */
                        i4500dsu1: report?.i4500Report?.dsu1,
                        i4500dsu2: report?.i4500Report?.dsu2,
                        i4500dsu3: report?.i4500Report?.dsu3,
                        i4500dsu4: report?.i4500Report?.dsu4,
                        /* i4500dsu5: report?.i4500Report?.dsu5,
                        i4500dsu6: report?.i4500Report?.dsu6,
                        i4500mdataOffloadCurrent: report?.i4500Report?.mdataOffloadCurrent,
                        i4500mdataOffloadArchive: report?.i4500Report?.mdataOffloadArchive,
                        i4500vikKit: report?.i4500Report?.vikKit,
                        i4500vikKitReplaced: report?.i4500Report?.vikKitReplaced,
                        i4500powerSourceType: report?.i4500Report?.powerSourceType, */
                        i4500confirmedCIL: report?.i4500Report?.confirmedCIL,
                        i4500activeMonth: report?.i4500Report?.activeMonth,
                        /* i4500billingMonth: report?.i4500Report?.billingMonth,*/
                        i4500loadPercent: report?.i4500Report?.loadPercent,
                        /* i4500loadingDelayCode: report?.i4500Report?.loadingDelayCode,
                        i4500loadingDelayNotes: report?.i4500Report?.loadingDelayNotes, */
                        i4500notes: report?.i4500Report?.notes,
                        /* i4500waitingForApproval: report?.i4500Report?.waitingForApproval,
                        i4500approved: report?.i4500Report?.approved, */
                      };
                    })}
                  >
                    <Button className="header-button-2">Export i4500 to CSV</Button>
                  </CSVLink>
                  <CSVLink
                    filename={`${batch?.batchName}_Gogo_export.csv`}
                    data={gogoExportData?.map((report) => {
                      return {
                        fin: report?.fin,
                        mediaType: report?.mediaType,
                        model: report?.model,
                        gogoStation: report?.gogoReport?.station,
                        gogoDate: report?.gogoReport?.date && formatDate(report?.gogoReport?.date),
                        gogoSerialNumberOn: report?.gogoReport?.serialNumberOn,
                        gogoSerialNumberOff: report?.gogoReport?.serialNumberOff,
                        gogoLoadPercent: report?.gogoReport?.loadPercent,
                        gogoNotes: report?.gogoReport?.notes,
                      };
                    })}
                  >
                    <Button className="header-button-2">Export GoGo to CSV</Button>
                  </CSVLink>
                  <CSVLink
                    filename={`${batch?.batchName}_Avant_export.csv`}
                    data={avantExportData?.map((report) => {
                      // if (report?.mediaType === 'Avant') {
                      return {
                        fin: report?.fin,
                        mediaType: report?.mediaType,
                        model: report?.model,
                        currentStatus: report?.currentStatus,
                        // avantUser: report?.avantReport?.user,
                        avantStation: report?.avantReport?.station,
                        avantTCLUsed: report?.avantReport?.tclUsed,
                        avantDate: report?.avantReport?.date && formatDate(report?.avantReport?.date),
                        avantLoadTimeMins: report?.avantReport?.loadTimeMins,
                        avantTotalTimeMins: report?.avantReport?.totalTimeMins,
                        avantCil: report?.avantReport?.cil,
                        avantDtdOn: report?.avantReport?.dtdOn,
                        avantDtdOff: report?.avantReport?.dtdOff,
                        avantDsu1: report?.avantReport?.dsu1,
                        avantDsu2: report?.avantReport?.dsu2,
                        avantArrive24h: report?.avantReport?.arrive24h,
                        avantPowerTCL: report?.avantReport?.powerTCL,
                        avantSystemReady: report?.avantReport?.systemReady,
                        avantDownloadStart: report?.avantReport?.downloadStart,
                        avantActiveDsuDownloaded: report?.avantReport?.activeDsuDownloaded,
                        avantActiveDsuInstalled: report?.avantReport?.activeDsuInstalled,
                        avantActiveSvdu5Downloaded: report?.avantReport?.activeSvdu5Downloaded,
                        avantActiveSvdu5Installed: report?.avantReport?.activeSvdu5Installed,
                        avantInactiveDsuDownloaded: report?.avantReport?.inactiveDsuDownloaded,
                        avantInactiveDsuInstalled: report?.avantReport?.inactiveDsuInstalled,
                        avantInactiveSvdu5Downloaded: report?.avantReport?.inactiveSvdu5Downloaded,
                        avantInactiveSvdu5Installed: report?.avantReport?.inactiveSvdu5Installed,
                        avantDsuInactiveDownloadAt100: report?.avantReport?.dsuInactiveDownloadAt100,
                        avantDsuInactiveInstalledAt100: report?.avantReport?.dsuInactiveInstalledAt100,
                        avantSvdu5InactiveDownloadAt100: report?.avantReport?.svdu5InactiveDownloadAt100,
                        avantSvdu5InactiveInstalledAt100: report?.avantReport?.svdu5InactiveInstalledAt100,
                        // avantOkToRemoveTclLoader: report?.avantReport?.okToRemoveTclLoader,
                        avantActiveMonth: report?.avantReport?.activeMonth,
                        // avantQualityCheck: report?.avantReport?.qualityCheck,
                        // avantDepart: report?.avantReport?.depart,
                        avantLoadPercent: report?.avantReport?.loadPercent,
                        avantNotes: report?.avantReport?.notes,
                        avantSeatsNotWorking: report?.avantReport?.seatsNotWorking,
                        // avantWaitingForApproval: report?.avantReport?.waitingForApproval,
                        // avantApproved: report?.avantReport?.approved,
                        // avantDtdUser: report?.avantDtdReport?.user,
                        avantDtdStation: report?.avantDtdReport?.station,
                        //avantDtdTCLUsed: report?.avantDtdReport?.tclUsed,
                        avantDtdDate: report?.avantDtdReport?.date && formatDate(report?.avantDtdReport?.date),
                        avantDtdDtdSerialOn: report?.avantDtdReport?.dtdSerialOn,
                        avantDtdDtdSerialOff: report?.avantDtdReport?.dtdSerialOff,
                        avantDtdDtdMonth: report?.avantDtdReport?.dtdMonth,
                        // avantDtdLoadPercent: report?.avantDtdReport?.loadPercent,
                        // avantDtdNotes: report?.avantDtdReport?.notes,
                        // avantDtdSeatsNotWorking: report?.avantDtdReport?.seatsNotWorking,
                        // avantDtdWaitingForApproval: report?.avantDtdReport?.waitingForApproval,
                        // avantDtdApproved: report?.avantDtdReport?.approved,
                      };
                      // }
                    })}
                  >
                    <Button className="header-button-2">Export Avant to CSV</Button>
                  </CSVLink>

                  <CSVLink
                    filename={`${batch?.batchName}_ATPDR_export.csv`}
                    data={ATPDRExportData?.map((report) => {
                      return {
                        fin: report?.fin,
                        mediaType: report?.mediaType,
                        model: report?.model,
                        currentStatus: report?.currentStatus,
                        atpdrUser: report?.atpdrReport?.user,
                        atpdrStation: report?.atpdrReport?.station,
                        atpdrFlightNumber: report?.atpdrReport?.flightNumber,
                        atpdrDisability: report?.atpdrReport?.disability,
                        atpdrDate: report?.atpdrReport?.date && formatDate(report?.atpdrReport?.date),
                        atpdrCaseNumberOn: report?.atpdrReport?.caseNumberOn,
                        atpdrCaseNumberOff: report?.atpdrReport?.caseNumberOff,
                        atpdrMonth: report?.atpdrReport?.month,
                        atpdrLoadPercent: report?.atpdrReport?.loadPercent,
                        atpdrNotes: report?.atpdrReport?.notes,
                        atpdrWaitingForApproval: report?.atpdrReport?.waitingForApproval,
                        atpdrApproved: report?.atpdrReport?.approved,
                      };
                    })}
                  >
                    <Button className="header-button-2">Export ATPDR to CSV</Button>
                  </CSVLink>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <ApprovalsPendingTable batch={batch} />

              <WaitingForSubmissionTable batch={batch} />

              <Title level={4} style={{ marginTop: '4%' }}>
                Approved Load Reports
              </Title>

              <ContentLoadingCard batch={batch} title="Content Loading Report - Approved Reports" />
              <Title level={4} style={{ marginTop: '4%' }}>
                Individual Reports
              </Title>
              <BatchReportsTable data={batch?.aircraftReports} batchId={id} />
            </Col>
          </Row>
        </>
      ) : (
        <>No permission to view this page</>
      )}
    </MainLayout>
  );
}
