import React, { useState, useEffect } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Spin, Modal, Row, Tag } from 'antd';
import axios from 'axios';
import AddAircraftModal from './AddAircraftModal';

export default function AircraftTable(props) {
  const [isAircraftModalVisible, setIsAircraftModalVisible] = useState(false);
  const [aircraftSelected, setAircraftSelected] = useState(null);
  const [finFilters, setFinFilters] = useState([]);

  const showAircraftModal = () => {
    setIsAircraftModalVisible(true);
  };

  const handleAircraftOk = () => {
    setIsAircraftModalVisible(false);
    setAircraftSelected(null);
  };

  const handleAircraftCancel = () => {
    setIsAircraftModalVisible(false);
    setAircraftSelected(null);
  };

  const columns = [
    {
      title: 'Fin',
      dataIndex: 'fin',
      key: 'fin',
      filters: finFilters,
      filterSearch: true,
      onFilter: (value, record) => record.fin === value,
    },
    {
      title: 'Media Type',
      dataIndex: 'mediaType',
      key: 'mediaType',
      filters: [
        { text: 'i4500', value: 'i4500' },
        { text: 'ATPDR', value: 'ATPDR' },
        { text: 'Avant', value: 'Avant' },
        { text: 'GoGo', value: 'GoGo' },
      ],
      onFilter: (value, record) => record.mediaType === value,
      render: (text, record) => {
        switch (text) {
          case 'i4500':
            return <Tag color="orange">i4500</Tag>;
          case 'ATPDR':
            return <Tag color="purple">ATPDR</Tag>;
          case 'Avant':
            return <Tag color="blue">Avant</Tag>;
          case 'GoGo':
            return <Tag color="red">GoGo</Tag>;
          default:
            return <Tag>-</Tag>;
        }
      },
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    // {
    //   title: 'Last Load',
    //   dataIndex: 'lastLoad',
    //   key: 'lastLoad',
    // },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        if (props.editAircraftPermission) {
          return (
            <Row>
              <DeleteOutlined style={{ color: 'red', marginRight: 8 }} onClick={() => confirmDelete(record._id)} />
              <EditOutlined
                style={{ color: '#40a9ff' }}
                onClick={() => {
                  showAircraftModal();
                  setAircraftSelected(record);
                }}
              />
            </Row>
          );
        } else {
          return <></>;
        }
      },
      width: '10%',
    },
  ];

  // Set fin filters
  useEffect(() => {
    props.data.map((aircraft) => {
      setFinFilters((prev) => [...prev, { text: aircraft.fin, value: aircraft.fin }]);
    });
  }, [props.data]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.handleCheck(selectedRows);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this aircraft?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        axios
          .delete(`/api/aircraft/${id}`)
          .then(() => {
            window.location.href = '/aircraft';
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };

  return props.data !== [] ? (
    <>
      <AddAircraftModal
        aircraftSelected={aircraftSelected}
        visible={isAircraftModalVisible}
        onOk={handleAircraftOk}
        onCancel={handleAircraftCancel}
        fetchAircraft={props.fetchAircraft}
      />
      <Table
        columns={columns}
        dataSource={props.data}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        rowKey="_id"
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
      />
    </>
  ) : (
    <Spin />
  );
}
