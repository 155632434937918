import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Select, Button, Checkbox, TimePicker } from 'antd';
import DatePicker from '../DatePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const SAVE_AFTER_MS = 1000;

export default function I4500EditCard({ report, batchId }) {
  const auth = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const autoSaveTimer = useRef();

  const [readyToSave, setReadyToSave] = useState(false);
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (sessionStorage?.form) {
      if (JSON.parse(sessionStorage?.form)?.reportId === report._id) {
        console.log('checking session');

        let sessionFields = JSON.parse(sessionStorage.form);
        console.log('sessionFields', sessionFields);

        form.setFieldsValue({
          station: sessionFields?.station || '',
          timeOnAircraft: sessionFields?.timeOnAircraft || '',
          timeOffAircraft: sessionFields?.timeOffAircraft || '',
          loadStartedTime: sessionFields?.loadStartedTime || '',
          loadTimeMins: sessionFields?.loadTimeMins || '',
          totalTimeMins: sessionFields?.totalTimeMins || '',
          currentAircraftMonthOnAC: sessionFields?.currentAircraftMonthOnAC || '',
          percentAtStart: sessionFields?.percentAtStart || '',
          safetyAds: sessionFields?.safetyAds || '',
          safetyVideo: sessionFields?.safetyVideo || '',
          xmRadio: sessionFields?.xmRadio || '',
          hollywoodNewTv: sessionFields?.hollywoodNewTv || '',
          prams: sessionFields?.prams || '',
          other: sessionFields?.other || '',
          pdl1: sessionFields?.pdl1 || '',
          pdl2: sessionFields?.pdl2 || '',
          hd1: sessionFields?.hd1 || '',
          hd2: sessionFields?.hd2 || '',
          dsu1: sessionFields?.dsu1 || '',
          dsu2: sessionFields?.dsu2 || '',
          dsu3: sessionFields?.dsu3 || '',
          dsu4: sessionFields?.dsu4 || '',
          mdataOffloadCurrent: sessionFields?.mdataOffloadCurrent || '',
          mdataOffloadArchive: sessionFields?.mdataOffloadArchive || '',
          vikKit: sessionFields?.vikKit || '',
          vikKitReplaced: sessionFields?.vikKitReplaced || '',
          powerSourceType: sessionFields?.powerSourceType || '',
          confirmedCIL: sessionFields?.confirmedCIL || '',
          activeMonth: sessionFields?.activeMonth || '',
          billingMonth: sessionFields?.billingMonth || '',
          loadPercent: sessionFields?.loadPercent || '',
          loadingDelayCode: sessionFields?.loadingDelayCode || '',
          loadingDelayNotes: sessionFields?.loadingDelayNotes || '',
          notes: sessionFields?.notes || '',
        });

        if (sessionFields?.date) {
          form.setFieldsValue({
            date: dayjs(sessionFields?.date) || '',
          });
        }
      }
    } else {
      console.log('checking db', report?.i4500Report);

      form.setFieldsValue({
        station: report?.i4500Report?.station || '',
        timeOnAircraft: report?.i4500Report?.timeOnAircraft || '',
        timeOffAircraft: report?.i4500Report?.timeOffAircraft || '',
        loadStartedTime: report?.i4500Report?.loadStartedTime || '',
        loadTimeMins: report?.i4500Report?.loadTimeMins || '',
        totalTimeMins: report?.i4500Report?.totalTimeMins || '',
        currentAircraftMonthOnAC: report?.i4500Report?.currentAircraftMonthOnAC || '',
        percentAtStart: report?.i4500Report?.percentAtStart || '',
        safetyAds: report?.i4500Report?.safetyAds || '',
        safetyVideo: report?.i4500Report?.safetyVideo || '',
        xmRadio: report?.i4500Report?.xmRadio || '',
        hollywoodNewTv: report?.i4500Report?.hollywoodNewTv || '',
        prams: report?.i4500Report?.prams || '',
        other: report?.i4500Report?.other || '',
        pdl1: report?.i4500Report?.pdl1 || '',
        pdl2: report?.i4500Report?.pdl2 || '',
        hd1: report?.i4500Report?.hd1 || '',
        hd2: report?.i4500Report?.hd2 || '',
        dsu1: report?.i4500Report?.dsu1 || '',
        dsu2: report?.i4500Report?.dsu2 || '',
        dsu3: report?.i4500Report?.dsu3 || '',
        dsu4: report?.i4500Report?.dsu4 || '',
        mdataOffloadCurrent: report?.i4500Report?.mdataOffloadCurrent || '',
        mdataOffloadArchive: report?.i4500Report?.mdataOffloadArchive || '',
        vikKit: report?.i4500Report?.vikKit || '',
        vikKitReplaced: report?.i4500Report?.vikKitReplaced || '',
        powerSourceType: report?.i4500Report?.powerSourceType || '',
        confirmedCIL: report?.i4500Report?.confirmedCIL || '',
        activeMonth: report?.i4500Report?.activeMonth || '',
        billingMonth: report?.i4500Report?.billingMonth || '',
        loadPercent: report?.i4500Report?.loadPercent || '',
        loadingDelayCode: report?.i4500Report?.loadingDelayCode || '',
        loadingDelayNotes: report?.i4500Report?.loadingDelayNotes || '',
        notes: report?.i4500Report?.notes || '',
      });

      if (report?.i4500Report?.date) {
        form.setFieldsValue({
          date: dayjs(report?.i4500Report?.date) || '',
        });
      }
    }
  }, [report]);

  const onFinish = (values) => {
    console.log('Success:', values);

    // Put to database
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          values?.loadPercent > 0 && values?.loadPercent < 100
            ? 'Partial Load'
            : values?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        i4500Report: {
          user: auth?.email,
          station: values?.station,
          date: values?.date,
          timeOnAircraft: values?.timeOnAircraft,
          timeOffAircraft: values?.timeOffAircraft,
          loadTimeMins: values?.loadTimeMins,
          totalTimeMins: values?.totalTimeMins,
          currentAircraftMonthOnAC: values?.currentAircraftMonthOnAC,
          percentAtStart: values?.percentAtStart,
          safetyAds: values.safetyAds || false,
          safetyVideo: values.safetyVideo || false,
          xmRadio: values.xmRadio || false,
          hollywoodNewTv: values.hollywoodNewTv || false,
          prams: values.prams || false,
          other: values.other || false,
          pdl1: values?.pdl1,
          pdl2: values?.pdl2,
          hd1: values?.hd1,
          hd2: values?.hd2,
          dsu1: values?.dsu1,
          dsu2: values?.dsu2,
          dsu3: values?.dsu3,
          dsu4: values?.dsu4,
          mdataOffloadCurrent: values?.mdataOffloadCurrent,
          mdataOffloadArchive: values?.mdataOffloadArchive,
          vikKit: values?.vikKit,
          vikKitReplaced: values?.vikKitReplaced,
          powerSourceType: values?.powerSourceType,
          confirmedCIL: values?.confirmedCIL,
          activeMonth: values?.activeMonth,
          billingMonth: values?.billingMonth,
          loadStartedTime: values?.loadStartedTime,
          loadPercent: values?.loadPercent,
          loadingDelayCode: values?.loadingDelayCode,
          loadingDelayNotes: values?.loadingDelayNotes,
          notes: values?.notes,
          waitingForSubmission: false,
          waitingForApproval: true,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    console.log('onFieldsChange', changedFields, allFields);

    let sessionSave = {
      reportId: report._id,
      station: allFields[0].value,
      loadStartedTime: allFields[1].value,
      date: allFields[2].value,
      timeOnAircraft: allFields[3].value,
      timeOffAircraft: allFields[4].value,
      loadTimeMins: allFields[5].value,
      totalTimeMins: allFields[6].value,
      currentAircraftMonthOnAC: allFields[7].value,
      percentAtStart: allFields[8].value,
      safetyAds: allFields[9].value,
      safetyVideo: allFields[10].value,
      xmRadio: allFields[11].value,
      hollywoodNewTv: allFields[12].value,
      prams: allFields[13].value,
      other: allFields[14].value,
      pdl1: allFields[15].value,
      pdl2: allFields[16].value,
      hd1: allFields[17].value,
      hd2: allFields[18].value,
      dsu1: allFields[19].value,
      dsu2: allFields[20].value,
      dsu3: allFields[21].value,
      dsu4: allFields[22].value,
      mdataOffloadCurrent: allFields[23].value,
      mdataOffloadArchive: allFields[24].value,
      vikKit: allFields[25].value,
      vikKitReplaced: allFields[26].value,
      powerSourceType: allFields[27].value,
      confirmedCIL: allFields[28].value,
      activeMonth: allFields[29].value,
      billingMonth: allFields[30].value,
      loadPercent: allFields[31].value,
      loadingDelayCode: allFields[32].value,
      loadingDelayNotes: allFields[33].value,
      notes: allFields[34].value,
    };

    setFields(sessionSave);

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setReadyToSave(true);
    setIsSaving(true);

    autoSaveTimer.current = setTimeout(() => {
      sessionStorage.setItem('form', JSON.stringify(sessionSave));
      clearTimeout(autoSaveTimer.current);
      setIsSaving(false);
    }, SAVE_AFTER_MS);
  };

  const onSave = () => {
    // Put to database
    axios
      .put(`/api/batch/${batchId}/report/${report._id}`, {
        _id: report?._id,
        fin: report?.fin,
        mediaType: report?.mediaType,
        model: report?.model,
        lastLoad: report?.lastLoad,
        numberInBatch: report?.numberInBatch,
        currentStatus:
          fields?.loadPercent > 0 && fields?.loadPercent < 100
            ? 'Partial Load'
            : fields?.loadPercent >= 100
            ? 'Fully Loaded'
            : 'Not Loaded',
        i4500Report: {
          user: auth?.email,
          station: fields?.station,
          date: fields?.date,
          timeOnAircraft: fields?.timeOnAircraft,
          loadStartedTime: fields?.loadStartedTime,
          timeOffAircraft: fields?.timeOffAircraft,
          loadTimeMins: fields?.loadTimeMins,
          totalTimeMins: fields?.totalTimeMins,
          currentAircraftMonthOnAC: fields?.currentAircraftMonthOnAC,
          percentAtStart: fields?.percentAtStart,
          safetyAds: fields.safetyAds || false,
          safetyVideo: fields.safetyVideo || false,
          xmRadio: fields.xmRadio || false,
          hollywoodNewTv: fields.hollywoodNewTv || false,
          prams: fields.prams || false,
          other: fields.other || false,
          pdl1: fields?.pdl1,
          pdl2: fields?.pdl2,
          hd1: fields?.hd1,
          hd2: fields?.hd2,
          dsu1: fields?.dsu1,
          dsu2: fields?.dsu2,
          dsu3: fields?.dsu3,
          dsu4: fields?.dsu4,
          mdataOffloadCurrent: fields?.mdataOffloadCurrent,
          mdataOffloadArchive: fields?.mdataOffloadArchive,
          vikKit: fields?.vikKit,
          vikKitReplaced: fields?.vikKitReplaced,
          powerSourceType: fields?.powerSourceType,
          confirmedCIL: fields?.confirmedCIL,
          activeMonth: fields?.activeMonth,
          billingMonth: fields?.billingMonth,
          loadStartingAt: fields?.loadStartingAt,
          loadPercent: fields?.loadPercent,
          loadingDelayCode: fields?.loadingDelayCode,
          loadingDelayNotes: fields?.loadingDelayNotes,
          notes: fields?.notes,
          waitingForSubmission: true,
          waitingForApproval: false,
          approved: false,
        },
      })
      .then((res) => {
        console.log(res);
        sessionStorage.removeItem('form');
        window.location.href = `/batch/${batchId}/report/${report._id}`;
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <Card title={`i4500 Loading Details - Edit`}>
      <Form
        form={form}
        name="gogoForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item label="Station" name="station">
              <Select placeholder="Select a station" allowClear>
                <Option value="YUL">YUL</Option>
                <Option value="YVR">YVR</Option>
                <Option value="YYC">YYC</Option>
                <Option value="YYZ">YYZ</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xl={6} xs={24}>
            <Form.Item label="Load Started Time" name="loadStartedTime">
              <Input />
            </Form.Item>
          </Col>
          <Col xl={6} xs={24}>
            <Form.Item label="Date" name="date">
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Time On Aircraft" name="timeOnAircraft">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Time Off Aircraft" name="timeOffAircraft">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Load Time (Mins)" name="loadTimeMins">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Total Time (Mins)" name="totalTimeMins">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Current Active Month on AC" name="currentAircraftMonthOnAC">
              <Select placeholder="Select a month" allowClear>
                <Option value="JAN">JAN</Option>
                <Option value="FEB">FEB</Option>
                <Option value="MAR">MAR</Option>
                <Option value="APR">APR</Option>
                <Option value="MAY">MAY</Option>
                <Option value="JUN">JUN</Option>
                <Option value="JUL">JUL</Option>
                <Option value="AUG">AUG</Option>
                <Option value="SEP">SEP</Option>
                <Option value="OCT">OCT</Option>
                <Option value="NOV">NOV</Option>
                <Option value="DEC">DEC</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="% at Start" name="percentAtStart">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item name="safetyAds" valuePropName="checked">
              <Checkbox>Safety Ads</Checkbox>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item name="safetyVideo" valuePropName="checked">
              <Checkbox>Safety Video</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item name="xmRadio" valuePropName="checked">
              <Checkbox>XM Radio</Checkbox>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item name="hollywoodNewTv" valuePropName="checked">
              <Checkbox>Hollywood and New TV Titles</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item name="prams" valuePropName="checked">
              <Checkbox>Prams</Checkbox>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item name="other" valuePropName="checked">
              <Checkbox>Other</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="PDL 1" name="pdl1">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="PDL 2" name="pdl2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="HD 1" name="hd1">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="HD 2" name="hd2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="DSU1" name="dsu1">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="DSU2" name="dsu2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="DSU3" name="dsu3">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="DSU4" name="dsu4">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="MDATA OFFLOAD (CURRENT)" name="mdataOffloadCurrent">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="MDATA OFFLOAD (ARCHIVE)" name="mdataOffloadArchive">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="VIK Kit" name="vikKit">
              <Select placeholder="Select Option" allowClear>
                <Option value="Used">Used</Option>
                <Option value="New">New</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="VIK Kit Replaced?" name="vikKitReplaced">
              <Select placeholder="Select Option" allowClear>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Power Source Type " name="powerSourceType">
              <Select placeholder="Select Option" allowClear>
                <Option value="GP">GP</Option>
                <Option value="Hangar">Hangar</Option>
                <Option value="Bridge">Bridge</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Confirmed CIL as Displayed on Aircraft" name="confirmedCIL">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Active Month" name="activeMonth">
              <Select placeholder="Select a month" allowClear>
                <Option value="JAN">JAN</Option>
                <Option value="FEB">FEB</Option>
                <Option value="MAR">MAR</Option>
                <Option value="APR">APR</Option>
                <Option value="MAY">MAY</Option>
                <Option value="JUN">JUN</Option>
                <Option value="JUL">JUL</Option>
                <Option value="AUG">AUG</Option>
                <Option value="SEP">SEP</Option>
                <Option value="OCT">OCT</Option>
                <Option value="NOV">NOV</Option>
                <Option value="DEC">DEC</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Billing Month" name="billingMonth">
              <Select placeholder="Select a month" allowClear>
                <Option value="JAN">JAN</Option>
                <Option value="FEB">FEB</Option>
                <Option value="MAR">MAR</Option>
                <Option value="APR">APR</Option>
                <Option value="MAY">MAY</Option>
                <Option value="JUN">JUN</Option>
                <Option value="JUL">JUL</Option>
                <Option value="AUG">AUG</Option>
                <Option value="SEP">SEP</Option>
                <Option value="OCT">OCT</Option>
                <Option value="NOV">NOV</Option>
                <Option value="DEC">DEC</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Load %" name="loadPercent">
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Loading Delay Code" name="loadingDelayCode">
              <Select placeholder="Select Option" allowClear>
                <Option value="Parked - no Power">Parked - no Power</Option>
                <Option value="Parked - no Stairs or access to aircraft">
                  Parked - no Stairs or access to aircraft
                </Option>
                <Option value="Planned A-Check">Planned A-Check</Option>
                <Option value="IFE system in-op">IFE system in-op</Option>
                <Option value="Aircraft in and out of Hangar">Aircraft in and out of Hangar</Option>
                <Option value="Parked - Inaccessable location of airport">
                  Parked - Inaccessable location of airport
                </Option>
                <Option value="No access to aircraft - for Crew Training">
                  No access to aircraft - for Crew Training
                </Option>
                <Option value="No access to aircraft - Security Reasons">
                  No access to aircraft - Security Reasons
                </Option>
                <Option value="Aircraft requires Engine Runs">Aircraft requires Engine Runs</Option>
                <Option value="Aircraft can not be powered - ie Fuel Tank Entry">
                  Aircraft can not be powered - ie Fuel Tank Entry
                </Option>
                <Option value="No available (faulty) ground power - Gate, GPU, Hangar">
                  No available (faulty) ground power - Gate, GPU, Hangar
                </Option>
                <Option value="Maintenance working on non IFE problems">Maintenance working on non IFE problems</Option>
                <Option value="Maintenance working on IFE snags">Maintenance working on IFE snags</Option>
                <Option value="IFE turned off due to cabin over heat">IFE turned off due to cabin over heat</Option>
                <Option value="IFE won't power up due to extreme cold external temperatures">
                  IFE won't power up due to extreme cold external temperatures
                </Option>
                <Option value="IROP's">IROP's</Option>
                <Option value="Pressure Wash">Pressure Wash</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={11} xs={24}>
            <Form.Item label="Loading Delay Notes" name="loadingDelayNotes">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col xl={12} xs={24}>
            <Form.Item label="Notes" name="notes">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="submit-button">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

          <Button type="primary" onClick={onSave} disabled={!readyToSave} style={{ marginLeft: 20 }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
